import { useMemo } from "react";

import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { useRowSelect } from "react-table/src/plugin-hooks/useRowSelect";
import { useSortBy } from "react-table/src/plugin-hooks/useSortBy";
import { Field } from "redux-form";

import { DndTable, FormControl } from "@dpdgroupuk/mydpd-ui";

import { ImportsFields } from "~/constants/forms";
import * as S from "~/constants/strings";
import { ImportsModels } from "~/pages/Imports/models";

import TableContainer from "../TableContainer";
import styles from "./FieldsOptions.module.scss";

const FieldsOptions = ({
  templates,
  excludeFields,
  setSelectedExcludeFields,
  selectedExcludeRowIds,
  onChangeDefinition,
  includeFields,
  setSelectedIncludeFields,
  selectedIncludeRowIds,
  onDragRowEnd,
  selectedExcludeFields,
  selectedIncludeFields,
  getAvailableFieldsFooterActions,
  getIncludeFieldsFooterActions,
  includeFieldsColumns,
}) => {
  const getAvailableFieldsHeaderActions = () => (
    <Col className="px-0">
      <Field
        label={S.FILE_DEFINITION}
        component={FormControl.Dropdown}
        name={ImportsFields.DEFINITION}
        values={templates}
        textTransform={S.UPPERCASE}
        onChange={onChangeDefinition}
      />
    </Col>
  );

  const tableEntities = useMemo(
    () => [
      {
        columns: ImportsModels.AVAILABLE_FIELDS_COLUMNS,
        data: excludeFields,
        droppableId: ImportsFields.EXCLUDE,
        draggableKeyId: "templateFieldId",
        headerActions: getAvailableFieldsHeaderActions,
        footerActions: getAvailableFieldsFooterActions,
        getSelectedRows: setSelectedExcludeFields,
        initialState: {
          selectedRowIds: selectedExcludeRowIds,
        },
        importCardValues: {
          step: 1,
          title: S.AVAILABLE_FIELDS,
          columns: 5,
        },
        classes: {
          container: styles.availableTableContainer,
          ghostingRow: styles.ghostingRow,
        },
      },
      {
        columns: includeFieldsColumns,
        data: includeFields,
        droppableId: ImportsFields.INCLUDE,
        draggableKeyId: "templateFieldId",
        footerActions: getIncludeFieldsFooterActions,
        getSelectedRows: setSelectedIncludeFields,
        initialState: {
          selectedRowIds: selectedIncludeRowIds,
        },
        importCardValues: {
          step: 2,
          title: S.DATA_FILE_FIELD_ORDER,
          columns: 7,
          // TODO: pass classes directly (refactor mydpd-ui)
          classes: {
            removeButtons: `d-flex justify-content-end justify-content-md-end justify-content-lg-start pt-3 ${styles.actionSectionShiftStart}`,
            moveButtons: `d-flex justify-content-end pt-3 ${styles.actionSectionShiftEnd}`,
          },
        },
      },
    ],
    [
      JSON.stringify(excludeFields),
      JSON.stringify(includeFields),
      JSON.stringify(selectedExcludeRowIds),
      JSON.stringify(selectedIncludeRowIds),
      JSON.stringify(selectedExcludeFields),
      JSON.stringify(selectedIncludeFields),
      includeFieldsColumns,
    ]
  );

  return (
    <DndTable
      onDragEnd={onDragRowEnd}
      tableEntities={tableEntities}
      isDraggableTable={true}
      tableHooks={[useSortBy, useRowSelect]}
      container={TableContainer}
      containerId="fields-options-table-container"
      classes={{
        container: styles.tableContainer,
        ghostingRow: styles.ghostingRow,
      }}
    />
  );
};

FieldsOptions.propTypes = {
  onClickRemoveField: PropTypes.func,
  onClickRemoveAllFields: PropTypes.func,
  onClickMoveUp: PropTypes.func,
  onClickMoveDown: PropTypes.func,
  includeFields: PropTypes.array,
  onClickRemoveAll: PropTypes.func,
  templateTranslations: PropTypes.array,
  setSelectedIncludeFields: PropTypes.func,
  onClickRemove: PropTypes.func,
  formValues: PropTypes.object,
  selectedIncludeRowIds: PropTypes.object,
  step: PropTypes.number,
  onClickAddAllFields: PropTypes.func,
  onClickAddField: PropTypes.func,
  templates: PropTypes.array,
  onChangeDefinition: PropTypes.func,
  excludeFields: PropTypes.array,
  onClickAdd: PropTypes.func,
  onClickAddAll: PropTypes.func,
  setSelectedExcludeFields: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDragRowEnd: PropTypes.func,
  getAvailableFieldsFooterActions: PropTypes.func,
  getIncludeFieldsFooterActions: PropTypes.func,
  selectedExcludeRowIds: PropTypes.object,
  selectedExcludeFields: PropTypes.array,
  selectedIncludeFields: PropTypes.array,
  includeFieldsColumns: PropTypes.array,
};

export default FieldsOptions;
