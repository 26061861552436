import { get } from "lodash";
import { sortBy } from "lodash/collection";
import { isEmpty } from "lodash/lang";
import { Field, getFormValues } from "redux-form";
import { createSelector } from "reselect";

import { TEMPLATE_TYPE } from "@dpdgroupuk/mydpd-enums";
import { FormControl, getFileAttributes } from "@dpdgroupuk/mydpd-ui";

import { SHIPPING_TEMPLATES } from "~/apis/basePaths";
import {
  IMPORT_TEMPLATE_FORM,
  IMPORTS_FORM,
  ImportsFields,
} from "~/constants/forms";
import * as S from "~/constants/strings";
import { ImportsModels } from "~/pages/Imports/models";
import { UmsSelectors } from "~/redux";
import { ProfilesSelectors } from "~/redux/profile";
import {
  getAddressbookDelimiters,
  getDelimiters,
  getProductBookDelimiters,
  getShipmentImportTemplates,
} from "~/redux/template/selectors";
import { getValue } from "~/utils/object";
import cloneDeep from "lodash/cloneDeep";
import styles from "~/pages/Imports/components/FieldsOptions/FieldsOptions.module.scss";

const memoizedEmptyObject = {};

export const getData = state => state.ui.imports;

export const getFormState = state => get(state, `form.${IMPORTS_FORM}`, {});

export const getImportsFormValues = state =>
  getFormValues(IMPORTS_FORM)(state) || memoizedEmptyObject;

export const getImportTemplateFormValues = state =>
  getFormValues(IMPORT_TEMPLATE_FORM)(state) || memoizedEmptyObject;

export const getTemplate = createSelector(getData, ({ template }) => template);

export const getExcludeFields = createSelector(getImportsFormValues, values =>
  ImportsModels.getExcludeFields(values)
);

export const getIncludeFields = createSelector(getImportsFormValues, values =>
  ImportsModels.getIncludeFields(values)
);

export const getPrintSequence = createSelector(
  getData,
  ({ printSequence }) => printSequence
);

export const getTemplateTranslationList = createSelector(
  getData,
  ({ templateTranslationList }) => sortBy(templateTranslationList, "label")
);

export const getHiddenData = createSelector(
  getImportsFormValues,
  getShipmentImportTemplates,
  ProfilesSelectors.isHiddenProfile,
  UmsSelectors.getCustomerServices,
  (formValues, shipmentImportTemplates, isHidden, customerServices) => {
    const definition = parseInt(
      getValue(formValues, ImportsFields.DEFINITION, "")
    );
    const isHiddenProfile = isHidden || definition !== TEMPLATE_TYPE.SHIPMENT;
    const hideShipment =
      definition !== TEMPLATE_TYPE.SHIPMENT || isEmpty(shipmentImportTemplates);
    const hideShipmentReceipt = definition !== TEMPLATE_TYPE.SHIPMENT_RECEIPT;
    // @see: https://it.dpduk.live/it/diagram/diag_THdPfb6GAqAAhTih.html
    const hideProductFieldDelimiter =
      definition === TEMPLATE_TYPE.SHIPMENT &&
      !customerServices.services.includes(S.DPD_DIRECT_SERVICE);

    return {
      delete: hideShipment || shipmentImportTemplates.length === 1,
      import: definition !== TEMPLATE_TYPE.SHIPMENT && hideShipmentReceipt,
      changePrinterSettings: hideShipmentReceipt,

      [ImportsFields.DEFAULT_TEMPLATE]:
        hideShipment || shipmentImportTemplates.length === 1,
      [ImportsFields.TEMPLATE_NAME]:
        hideShipment || shipmentImportTemplates.length === 1,
      [ImportsFields.PRINT_SEQUENCE]:
        definition !== TEMPLATE_TYPE.SHIPMENT ||
        !getValue(formValues, ImportsFields.AUTO_PRINT),
      [ImportsFields.WEIGHT_OVERRIDE_TOGGLE]:
        definition === TEMPLATE_TYPE.SHIPMENT,
      [ImportsFields.OVERRIDE_WEIGHT]:
        definition !== TEMPLATE_TYPE.SHIPMENT ||
        !getValue(formValues, ImportsFields.WEIGHT_OVERRIDE_TOGGLE, false),
      [ImportsFields.CREATE_RECEIPT]: hideShipmentReceipt,
      [ImportsFields.ONE_RECEIPT_PER_SHIPMENT]:
        hideShipmentReceipt ||
        !getValue(formValues, ImportsFields.CREATE_RECEIPT),
      [ImportsFields.AUTO_PRINT]: definition === TEMPLATE_TYPE.SHIPMENT,
      [ImportsFields.DISABLE_BAD_LABELS]: definition === TEMPLATE_TYPE.SHIPMENT,
      [ImportsFields.AUTO_IMPORT]: definition === TEMPLATE_TYPE.SHIPMENT,
      [ImportsFields.HEADER_LINES]: definition === TEMPLATE_TYPE.SHIPMENT,
      [ImportsFields.FOOTER_LINES]: definition === TEMPLATE_TYPE.SHIPMENT,
      [ImportsFields.PROFILE_CODE]: isHiddenProfile,
      [ImportsFields.EXPORT_FILE_BEHAVIOR]:
        definition !== TEMPLATE_TYPE.SHIPMENT_EXPORT,
      workDirectory:
        hideShipmentReceipt &&
        definition !== TEMPLATE_TYPE.SHIPMENT_EXPORT &&
        definition !== TEMPLATE_TYPE.SHIPMENT,
      extension: hideShipmentReceipt && definition !== TEMPLATE_TYPE.SHIPMENT,
      delimiter:
        hideShipmentReceipt &&
        ![
          TEMPLATE_TYPE.SHIPMENT_EXPORT,
          TEMPLATE_TYPE.SHIPMENT,
          TEMPLATE_TYPE.RETURN_ADDRESS_BOOK,
          TEMPLATE_TYPE.DELIVERY_ADDRESS_BOOK,
        ].includes(definition),
      productDelimiter:
        ![
          TEMPLATE_TYPE.DPD_DIRECT_PRODUCT,
          TEMPLATE_TYPE.PRODUCT,
          TEMPLATE_TYPE.SHIPMENT,
          TEMPLATE_TYPE.DELIVERY_ADDRESS_BOOK,
          TEMPLATE_TYPE.PRODUCT_BOOK,
        ].includes(definition) || hideProductFieldDelimiter,
      shipmentFields: definition !== TEMPLATE_TYPE.SHIPMENT,
    };
  }
);

export const getExportCsvLink = apisBaseUrl =>
  createSelector(getImportsFormValues, values =>
    getValue(values, ImportsFields.DEFINITION, "") ===
    TEMPLATE_TYPE.SHIPMENT.toString()
      ? `${apisBaseUrl}${SHIPPING_TEMPLATES}/${getValue(
          values,
          ImportsFields.TEMPLATE_NAME,
          ""
        )}/export`
      : `${apisBaseUrl}${SHIPPING_TEMPLATES}/shipment-receipt/csv`
  );

// @see https://it.dpduk.live/version/customer-shipping/sprint-2.1/diag_z6viE76GAqAAhWIt.html
export const getInitialSaveTemplateFormValues = createSelector(
  getImportsFormValues,
  getShipmentImportTemplates,
  (formValues, templates) => {
    const selectedTemplate = templates.find(
      template =>
        template.templateId ===
        getValue(formValues, [ImportsFields.TEMPLATE_NAME], "")
    );
    return {
      [ImportsFields.TEMPLATE_NAME]: getValue(
        selectedTemplate,
        [ImportsFields.TEMPLATE_NAME],
        null
      ),
    };
  }
);

export const getFieldNames = createSelector(
  getImportsFormValues,
  formValues => {
    switch (parseInt(getValue(formValues, ImportsFields.DEFINITION, ""))) {
      case TEMPLATE_TYPE.SHIPMENT_RECEIPT: {
        return {
          workDirectory: {
            name: ImportsFields.WORKING_DIR,
            label: S.EXPORT_DIRECTORY,
          },
          extension: {
            name: ImportsFields.EXTENSION,
            label: S.EXPORT_EXTENSION,
            // @see: https://it.dpduk.live/version/customer-shipping/ai-file-import/sprint-1/diag_oJPpnb6GAqAAhXqe.html
            maxLength: 4,
          },
          delimiter: {
            name: ImportsFields.FILE_FIELD_DELIMITER,
            label: S.FIELD_DELIMITER,
          },
        };
      }
      case TEMPLATE_TYPE.SHIPMENT_EXPORT: {
        return {
          workDirectory: {
            name: ImportsFields.EXPORT_FILE,
            label: S.EXPORT_FILE,
          },
          delimiter: {
            name: ImportsFields.DELIMITER,
            label: S.FIELD_DELIMITER,
          },
        };
      }
      case TEMPLATE_TYPE.PRODUCT:
      case TEMPLATE_TYPE.DPD_DIRECT_PRODUCT: {
        return {
          productDelimiter: {
            name: ImportsFields.PRODUCT_DELIMITER,
            label: S.PRODUCT_DELIMITER,
          },
        };
      }
      case TEMPLATE_TYPE.SHIPMENT: {
        return {
          workDirectory: {
            name: ImportsFields.WORKING_DIR,
            label: S.IMPORT_DIRECTORY,
          },
          extension: {
            name: ImportsFields.EXTENSION,
            label: S.IMPORT_EXTENSION,
            // @see: https://it.dpduk.live/version/customer-shipping/ai-file-import/sprint-1/diag_oJPpnb6GAqAAhXqe.html
            maxLength: 5,
          },
          delimiter: {
            name: ImportsFields.DELIMITER,
            label: S.FIELD_DELIMITER,
          },
          productDelimiter: {
            name: ImportsFields.PRODUCT_DELIMITER,
            label: S.PRODUCT_FIELD_DELIMITER,
          },
        };
      }
      case TEMPLATE_TYPE.DELIVERY_ADDRESS_BOOK: {
        return {
          delimiter: {
            name: ImportsFields.FILE_FIELD_DELIMITER,
            label: S.FIELD_DELIMITER,
          },
          productDelimiter: {
            name: ImportsFields.GROUP_NAME_FIELD_DELIMITER,
            label: S.GROUP_NAME_DELIMITER,
          },
        };
      }
      case TEMPLATE_TYPE.RETURN_ADDRESS_BOOK: {
        return {
          delimiter: {
            name: ImportsFields.FILE_FIELD_DELIMITER,
            label: S.FIELD_DELIMITER,
          },
        };
      }
      case TEMPLATE_TYPE.PRODUCT_BOOK: {
        return {
          productDelimiter: {
            name: ImportsFields.PRODUCT_DELIMITER,
            label: S.PRODUCTBOOK_DELIMITER,
          },
        };
      }

      default:
        break;
    }
  }
);

export const getDelimitersKeyValue = createSelector(
  getImportsFormValues,
  getDelimiters,
  getAddressbookDelimiters,
  getProductBookDelimiters,
  (formValues, delimiters, addressbookDelimiters, productBookDelimiters) => {
    const templateType = parseInt(
      getValue(formValues, ImportsFields.DEFINITION, "")
    );
    let result = delimiters;

    if (
      templateType === TEMPLATE_TYPE.DELIVERY_ADDRESS_BOOK ||
      templateType === TEMPLATE_TYPE.RETURN_ADDRESS_BOOK
    ) {
      result = addressbookDelimiters;
    }

    if (templateType === TEMPLATE_TYPE.PRODUCT_BOOK) {
      result = productBookDelimiters;
    }

    return result.map(
      ({ delimiterValue: value, delimiterDescription: label }) => ({
        label,
        value,
      })
    );
  }
);

export const getRegisteredFields = createSelector(getFormState, state =>
  Object.keys(getValue(state, "registeredFields", {}))
);

export const getTreePath = createSelector(
  getImportsFormValues,
  getFieldNames,
  (values, fieldNames) => {
    if (
      values[ImportsFields.DEFINITION] ===
      TEMPLATE_TYPE.SHIPMENT_EXPORT.toString()
    ) {
      const { directoryPath } = getFileAttributes(
        values[fieldNames.workDirectory.name]
      );
      return directoryPath;
    }
    if (
      [
        TEMPLATE_TYPE.SHIPMENT.toString(),
        TEMPLATE_TYPE.SHIPMENT_RECEIPT.toString(),
      ].includes(values[ImportsFields.DEFINITION])
    ) {
      return values[fieldNames.workDirectory.name];
    }
  }
);

export const getIncludeFieldsColumns = createSelector(
  getImportsFormValues,
  getTemplateTranslationList,
  (formValues, templateTranslations) => {
    if (
      getValue(formValues, ImportsFields.DEFINITION, "") ===
      TEMPLATE_TYPE.SHIPMENT.toString()
    ) {
      const res = cloneDeep(ImportsModels.FILE_ORDER_COLUMNS);
      res.splice(1, 0, {
        Header: S.TRANSLATION,
        Cell: ({ row }) =>
          !row.original.templateFieldId.startsWith("null") && (
            <Field
              component={FormControl.Dropdown}
              name={`includeFields[${row.id}].templateFieldTranslationCode`}
              values={templateTranslations}
              classes={{
                group: styles.translationGroup,
                input: styles.translationInput,
                dropdown: styles.translationDropdown,
                option: styles.translationOption,
              }}
              textTransform={S.UPPERCASE}
            />
          ),
      });

      return res;
    }

    return ImportsModels.FILE_ORDER_COLUMNS;
  }
);
