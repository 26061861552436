import { arrayRemoveAll, change } from "redux-form";

import { TEMPLATE_TYPE } from "@dpdgroupuk/mydpd-enums";
import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
  createPayloadAction,
} from "@dpdgroupuk/redux-action-creator";

import { templatesApi } from "~/apis";
import { IMPORTS_FORM, ImportsFields } from "~/constants/forms";
import * as S from "~/constants/strings";
import { IMPORT_DEFAULT_SEQUENCE } from "~/constants/strings";
import { ImportsModels } from "~/pages/Imports/models";
import { ImportsSelectors } from "~/pages/Imports/redux/index";
import { ProfilesSelectors, TemplateSelectors, UmsSelectors } from "~/redux";
import { getValue } from "~/utils/object";
import { initializeForm } from "~/utils/reduxForm";

export const ActionTypes = createActionTypes("IMPORTS", {
  GET_TEMPLATE_TRANSLATIONS: createAsyncActionTypes(
    "GET_TEMPLATE_TRANSLATIONS"
  ),
  UPDATE_PRINT_SEQUENCE: "UPDATE_PRINT_SEQUENCE",
  CLEAR_IMPORTS: "CLEAR_IMPORTS",
});

export const updatePrintSequence = sequence =>
  createPayloadAction(ActionTypes.UPDATE_PRINT_SEQUENCE, sequence);

export const fetchTemplateTranslations = createAsyncAction(
  () =>
    templatesApi.fetchShipmentTemplateTranslations().then(({ data }) => ({
      // @see https://it.dpduk.live/version/customer-shipping/sprint-2.1/diag_THdPfb6GAqAAhTih.html?id=1646917522670
      data: data.reduce(
        (acc, item) => {
          acc.push({
            label: item.translationDescription,
            value: item.translationCode,
          });
          return acc;
        },
        [S.NO_TRANSLATION_DEFAULT]
      ),
    })),
  ActionTypes.GET_TEMPLATE_TRANSLATIONS
);

export const removeExcludeIncludeFields = () => dispatch => {
  dispatch(arrayRemoveAll(IMPORTS_FORM, ImportsFields.EXCLUDE));
  dispatch(arrayRemoveAll(IMPORTS_FORM, ImportsFields.INCLUDE));
};

export const updateExcludeIncludeFields = data => dispatch => {
  dispatch(removeExcludeIncludeFields());
  dispatch(initializeForm(IMPORTS_FORM, data));
};

export const updateImportsForm = data => (dispatch, getState) => {
  dispatch(removeExcludeIncludeFields());
  const state = getState();
  const profiles = ProfilesSelectors.getProfiles(state);
  const definition = getValue(
    ImportsSelectors.getImportsFormValues(state),
    ImportsFields.DEFINITION,
    ""
  );
  const customerServices = UmsSelectors.getCustomerServices(state);

  definition !== TEMPLATE_TYPE.SHIPMENT.toString()
    ? dispatch(
        initializeForm(IMPORTS_FORM, {
          ...data,
          ...(definition === TEMPLATE_TYPE.SHIPMENT_EXPORT.toString() &&
            !data[ImportsFields.EXPORT_FILE_BEHAVIOR] && {
              [ImportsFields.EXPORT_FILE_BEHAVIOR]: "A",
            }),
        })
      )
    : dispatch(
        initializeForm(IMPORTS_FORM, {
          ...data,
          [ImportsFields.TEMPLATE_NAME]: data[ImportsFields.TEMPLATE_ID],
          [ImportsFields.WEIGHT_OVERRIDE_TOGGLE]:
            !!data[ImportsFields.OVERRIDE_WEIGHT],
          [ImportsFields.PRINT_SEQUENCE]: getValue(
            data,
            ImportsFields.PRINT_SEQUENCE,
            IMPORT_DEFAULT_SEQUENCE.value
          ).toString(),
          ...(!customerServices.services.includes(S.DPD_DIRECT_SERVICE) && {
            [ImportsFields.PRODUCT_DELIMITER]: null,
          }),
          ...(!profiles.find(
            profile => profile.profileCode === data[ImportsFields.PROFILE_CODE]
          ) && {
            [ImportsFields.PROFILE_CODE]: profiles[0].profileCode,
          }),
        })
      );
};

export const setDefaultShipmentImportTemplate = () => (dispatch, getState) =>
  dispatch(
    initializeForm(IMPORTS_FORM, {
      [ImportsFields.TEMPLATE_NAME]: ImportsModels.getDefaultTemplateId(
        TemplateSelectors.getShipmentImportTemplates(getState())
      ),
    })
  );

export const clearImportPage = () => ({
  type: ActionTypes.CLEAR_IMPORTS,
});

// @see: https://geopost.jira.com/browse/CSHIP-7905
export const updateAqaFileInput = file => dispatch => {
  const dataTransfer = new DataTransfer();
  dataTransfer.items.add(file);
  dispatch(
    change(IMPORTS_FORM, ImportsFields.AQA_FILE_INPUT, dataTransfer.files)
  );
};
