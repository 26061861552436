import React, { useCallback } from "react";

import get from "lodash/get";
import isEqual from "lodash/isEqual";
import { isEmpty } from "lodash/lang";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { propTypes } from "react-bootstrap/esm/Image";
import { connect } from "react-redux";
import {
  compose,
  lifecycle,
  withHandlers,
  withProps,
  withState,
} from "recompose";
import { change, reduxForm, reset, untouch } from "redux-form";

import {
  withAppContext,
  withAppLoader,
  withAppUserPreferences,
  withLocalServiceState,
  withNotifier,
  withUrlResolver,
} from "@dpdgroupuk/mydpd-app";
import { TEMPLATE_TYPE } from "@dpdgroupuk/mydpd-enums";
import {
  Button,
  Card,
  Legend,
  Main,
  withOverlay,
  withPrompt,
} from "@dpdgroupuk/mydpd-ui";

import { importsApi, localApi } from "~/apis";
import { IMPORTS_FORM, ImportsFields } from "~/constants/forms";
import * as S from "~/constants/strings";
import { withImportBanner } from "~/features";
import withInvisibleErrorHandler from "~/hocs/withInvisibleErrorHandler";
import importTemplateSchema from "~/models/validators/importTemplateSchema";
import { ImportsModels } from "~/pages/Imports/models";
import { ImportsActions, ImportsSelectors } from "~/pages/Imports/redux";
import {
  ProfilesActions,
  ProfilesSelectors,
  TemplateActions,
  TemplateSelectors,
} from "~/redux";
import createValidator from "~/utils/joiReduxForm";
import { getValue } from "~/utils/object";
import { initializeForm } from "~/utils/reduxForm";
import { formatMessage } from "~/utils/string";

import FieldsOptions from "./components/FieldsOptions";
import FileOptions from "./components/FileOptions";
import SaveTemplateModal from "./components/SaveTemplateModal";
import styles from "./Imports.module.scss";
import TestImportFile from "~/pages/ImportActivity/components/TestImportFile/TestImportFile";
import useToggle from "~/hooks/useToggle";
import AiImportModal from "~/pages/Imports/components/AiImportModal";
import withDndTableActions from "~/pages/Imports/hocs/withDndTableActions";
import { isAbortError } from "@dpdgroupuk/fetch-client";

const Imports = props => {
  const {
    hiddenData,
    onClickCancel,
    onClickDelete,
    onClickSave,
    handleSubmit,
    formValues,
    isOpenPopup,
    setIsOpenPopup,
    initialSaveTemplateFormValues,
    urlResolver,
    template,
    aiInitialData,
    templateTranslations,
    onClickAiImport,
    aiImportToggle,
    aiApplyToggle,
    updateExcludeIncludeFields,
    setAiInitialData,
  } = props;
  const onSave = useCallback(
    () =>
      formValues[ImportsFields.DEFINITION] !== TEMPLATE_TYPE.SHIPMENT.toString()
        ? onClickSave()
        : setIsOpenPopup(true),
    [formValues[ImportsFields.DEFINITION], onClickSave, setIsOpenPopup]
  );

  return (
    <Main>
      <Main.Body>
        <Legend
          rightMessage={get(props.authUser, "user.username")}
          classes={{ container: "p-0" }}
        />
        <Card.Stack fluid>
          <FieldsOptions {...props} />
          <Col
            key={3}
            xs={{ order: 3, span: 12 }}
            md={12}
            className={`${styles.col} p-0`}
          >
            <FileOptions
              {...props}
              onClickAiImport={onClickAiImport}
              step={3}
              totalSteps={3}
            />
          </Col>
          {!hiddenData.shipmentFields && (
            <Col
              key={4}
              xs={{ order: 4, span: 12 }}
              className={`${styles.col} p-0`}
            >
              <TestImportFile
                shipmentTemplate={template}
                fileExtension={template?.[ImportsFields.EXTENSION]}
                withLabel={true}
              />
            </Col>
          )}
        </Card.Stack>
      </Main.Body>
      <Main.Footer className="dark">
        <Button.Toolbar>
          {!hiddenData.changePrinterSettings && (
            <Button
              variant="light"
              href={urlResolver.getUrl("mydpd.account.printing-settings")}
            >
              {S.CHANGE_PRINTER_SETTINGS}
            </Button>
          )}
          {!hiddenData.delete && (
            <Button variant="light" onClick={onClickDelete}>
              {formatMessage(S._$_TEMPLATE, S.DELETE)}
            </Button>
          )}
          <Button variant="light" onClick={onClickCancel}>
            {S.CANCEL}
          </Button>
          <Button variant="light" onClick={handleSubmit(onSave)}>
            {formatMessage(S._$_TEMPLATE, S.SAVE)}
          </Button>
        </Button.Toolbar>
      </Main.Footer>
      {isOpenPopup && (
        <SaveTemplateModal
          open={isOpenPopup}
          onHide={() => setIsOpenPopup(false)}
          saveTemplate={onClickSave}
          initialValues={initialSaveTemplateFormValues}
        />
      )}
      <AiImportModal
        aiImportToggle={aiImportToggle}
        aiApplyToggle={aiApplyToggle}
        templateTranslations={templateTranslations}
        aiInitialData={aiInitialData}
        setAiInitialData={setAiInitialData}
        updateImportForm={updateExcludeIncludeFields}
      />
    </Main>
  );
};

Imports.propTypes = {
  ...propTypes,
  authUser: PropTypes.object,
  hiddenData: PropTypes.object,
  template: PropTypes.object,
  aiInitialData: PropTypes.object,
  aiImportToggle: PropTypes.object,
  aiApplyToggle: PropTypes.object,
  onClickCancel: PropTypes.func,
  onClickDelete: PropTypes.func,
  onClickSave: PropTypes.func,
  formValues: PropTypes.object,
  setIsOpenPopup: PropTypes.func,
  onClickAiImport: PropTypes.func,
  onSubmitAiSuggestion: PropTypes.func,
  onAiBackClick: PropTypes.func,
  updateExcludeIncludeFields: PropTypes.func,
  setAiInitialData: PropTypes.func,
  urlResolver: PropTypes.object,
  isOpenPopup: PropTypes.bool,
  initialSaveTemplateFormValues: PropTypes.object,
  templateTranslations: PropTypes.array,
  includeFieldsColumns: PropTypes.array,
};

export default compose(
  withAppContext,
  withPrompt,
  withUrlResolver,
  withOverlay,
  withState("aiInitialData", "setAiInitialData", {}),
  withState(
    "checkedOverrideWeightToggle",
    "setCheckedOverrideWeightToggle",
    false
  ),
  withState("isOpenPopup", "setIsOpenPopup", false),
  withAppUserPreferences,
  withNotifier,
  withInvisibleErrorHandler,
  withImportBanner,
  withLocalServiceState,
  withProps(() => ({
    aiImportToggle: useToggle(),
    aiApplyToggle: useToggle(),
  })),
  connect(
    (state, { app, localServiceState }) => ({
      templates: TemplateSelectors.getTemplatesKeyValue(state),
      delimiters: ImportsSelectors.getDelimitersKeyValue(state),
      profiles: ProfilesSelectors.getProfilesKeyValue(state),
      excludeFields: ImportsSelectors.getExcludeFields(state),
      includeFields: ImportsSelectors.getIncludeFields(state),
      includeFieldsColumns: ImportsSelectors.getIncludeFieldsColumns(state),
      printSequence: ImportsSelectors.getPrintSequence(state),
      templateTranslations: ImportsSelectors.getTemplateTranslationList(state),
      isRunning: localServiceState.isRunning,
      formValues: ImportsSelectors.getImportsFormValues(state),
      importTemplateFormValues:
        ImportsSelectors.getImportTemplateFormValues(state),
      hiddenData: ImportsSelectors.getHiddenData(state),
      fieldNames: ImportsSelectors.getFieldNames(state),
      shipmentImportTemplatesKeyValue:
        TemplateSelectors.getShipmentImportTemplatesKeyValue(state),
      template: TemplateSelectors.getTemplate(state),
      exportCsvLink: ImportsSelectors.getExportCsvLink(
        app.apis.getApisBaseUrl()
      )(state),
      initialSaveTemplateFormValues:
        ImportsSelectors.getInitialSaveTemplateFormValues(state),
      uiFields: ImportsSelectors.getRegisteredFields(state),
      userTreePath: ImportsSelectors.getTreePath(state),
    }),
    (
      dispatch,
      {
        notifier,
        setCheckedOverrideWeightToggle,
        overlay,
        setAiInitialData,
        aiImportToggle,
        aiApplyToggle,
      }
    ) => ({
      fetchTemplateTypes: notifier.runAsync(
        query => dispatch(TemplateActions.fetchTemplateTypes(query)),
        { entityName: S.TEMPLATES_TYPE_LIST }
      ),
      fetchDelimiters: notifier.runAsync(
        query => dispatch(TemplateActions.fetchDelimiters(query)),
        { entityName: S.DELIMITER_LIST }
      ),
      fetchTemplate: notifier.runAsync(
        value => dispatch(TemplateActions.fetchTemplate(value)),
        { entityName: S.TEMPLATE }
      ),
      fetchAddressbookDelimiters: notifier.runAsync(
        query => dispatch(TemplateActions.fetchAddressbookDelimiters(query)),
        { entityName: S.ADDRESSBOOK_DELIMITER_LIST }
      ),
      fetchShipmentImportTemplateById: overlay.showWhile(
        notifier.runAsync(
          id => dispatch(TemplateActions.fetchShipmentImportTemplateById(id)),
          { entityName: S.TEMPLATE }
        )
      ),
      fetchTemplateTranslations: notifier.runAsync(
        query => dispatch(ImportsActions.fetchTemplateTranslations(query)),
        { entityName: S.TEMPLATE_TRANSLATIONS }
      ),
      fetchShipmentImportTemplates: notifier.runAsync(
        query => dispatch(TemplateActions.fetchShipmentImportTemplates(query)),
        { entityName: S.SHIPMENT_IMPORT_TEMPLATES }
      ),
      fetchProfiles: notifier.runAsync(
        () => dispatch(ProfilesActions.fetchProfiles()),
        { entityName: S.PROFILES }
      ),
      deleteShipmentImportTemplateById: notifier.runAsync(
        () => dispatch(TemplateActions.deleteShipmentImportTemplateById()),
        { entityName: S.DELETE }
      ),
      fetchUserDirectories: notifier.runAsync(path => localApi.fs.dirs(path), {
        entityName: S.USER_DIRECTORIES,
      }),
      fetchUserTrees: path => localApi.fs.tree(path),
      checkPrinterStatus: () => localApi.ping(0),
      fetchProductbookDelimiters: notifier.runAsync(
        query => dispatch(TemplateActions.fetchProductbookDelimiters(query)),
        { entityName: S.PRODUCT_BOOK_DELIMITER_LIST }
      ),
      clearPage: () => {
        dispatch(ImportsActions.clearImportPage());
      },
      onChangeCreateReceiptFile: (event, newValue) =>
        !newValue &&
        dispatch(
          initializeForm(IMPORTS_FORM, {
            [ImportsFields.ONE_RECEIPT_PER_SHIPMENT]: false,
            [ImportsFields.ONE_LINE_PER_SHIPMENT]: false,
            [ImportsFields.USE_REFERENCE_FIVE]: false,
          })
        ),
      onChangeOneReceiptPerShipment: (event, newValue) =>
        !newValue &&
        dispatch(
          initializeForm(IMPORTS_FORM, {
            [ImportsFields.ONE_LINE_PER_SHIPMENT]: false,
            [ImportsFields.USE_REFERENCE_FIVE]: false,
          })
        ),
      onChangeOverrideWeightToggle: (event, newValue) => {
        if (!newValue) {
          dispatch(
            initializeForm(IMPORTS_FORM, {
              [ImportsFields.OVERRIDE_WEIGHT]: null,
            })
          );
          dispatch(untouch(IMPORTS_FORM, ImportsFields.OVERRIDE_WEIGHT));
        }
        setCheckedOverrideWeightToggle(newValue);
      },
      updateExcludeIncludeFields: data =>
        dispatch(ImportsActions.updateExcludeIncludeFields(data)),
      updateIncludeFields: data =>
        dispatch(change(IMPORTS_FORM, ImportsFields.INCLUDE, data)),
    })
  ),
  reduxForm({
    form: IMPORTS_FORM,
    validate: values => createValidator(importTemplateSchema(values))(values),
  }),
  withDndTableActions,
  withHandlers({
    onChangeDefinition: ({
      dispatch,
      fetchTemplate,
      fetchShipmentImportTemplates,
      overlay,
    }) =>
      overlay.showWhile(async (event, newValue) => {
        dispatch(reset(IMPORTS_FORM));
        if (newValue === TEMPLATE_TYPE.SHIPMENT.toString()) {
          await fetchShipmentImportTemplates();
          dispatch(ImportsActions.setDefaultShipmentImportTemplate());
        }
        await fetchTemplate(newValue);
      }),
    onClickCancel:
      ({
        dispatch,
        template,
        setSelectedExcludeRowIds,
        setSelectedIncludeRowIds,
        setCheckedOverrideWeightToggle,
      }) =>
      () => {
        dispatch(ImportsActions.updateImportsForm(template));
        setSelectedExcludeRowIds({});
        setSelectedIncludeRowIds({});
        setCheckedOverrideWeightToggle(
          !!template[ImportsFields.OVERRIDE_WEIGHT]
        );
        dispatch(
          change(
            IMPORTS_FORM,
            ImportsFields.WEIGHT_OVERRIDE_TOGGLE,
            !!template[ImportsFields.OVERRIDE_WEIGHT]
          )
        );
      },
    onClickImportTemplate: ({
      dispatch,
      snackbar,
      notifier,
      formValues,
      importTemplateFormValues,
      fetchShipmentImportTemplateById,
      fetchTemplate,
      fetchShipmentImportTemplates,
      overlay,
      uiFields,
      prompt,
      showInvisibleFieldError,
      setCheckedOverrideWeightToggle,
    }) =>
      overlay.showWhile(
        notifier.runAsync(
          showInvisibleFieldError(
            async () => {
              const file =
                importTemplateFormValues[ImportsFields.TEMPLATE_FILE][0];

              const errorMessage = await ImportsModels.validateFile(
                file,
                S.CSV_EXTENSION
              );

              if (errorMessage) {
                return snackbar.showAlert({
                  message: errorMessage,
                });
              }

              const formData = await ImportsModels.getFileFormData(
                file,
                "templateFile"
              );

              const data = await dispatch(
                TemplateActions.importTemplate(formData, formValues)
              );
              let template;

              if (
                formValues[ImportsFields.DEFINITION] ===
                TEMPLATE_TYPE.SHIPMENT.toString()
              ) {
                await fetchShipmentImportTemplates();
                template = await fetchShipmentImportTemplateById(
                  data.templateId
                );
              }

              if (
                formValues[ImportsFields.DEFINITION] ===
                TEMPLATE_TYPE.SHIPMENT_RECEIPT.toString()
              ) {
                template = await fetchTemplate(
                  formValues[ImportsFields.DEFINITION]
                );
              }

              dispatch(ImportsActions.updateImportsForm(template));
              setCheckedOverrideWeightToggle(
                !!template[ImportsFields.OVERRIDE_WEIGHT]
              );
              prompt.showSuccess({
                header: S.SUCCESS,
                message: S.TEMPLATE_IMPORTED_SUCCESSFULLY,
              });
            },
            { uiFields }
          ),
          { entityName: S.TEMPLATE }
        )
      ),
    onClickDelete: ({
      deleteShipmentImportTemplateById,
      fetchShipmentImportTemplates,
      fetchShipmentImportTemplateById,
      notifier,
      prompt,
      overlay,
    }) =>
      // @see: https://it.dpduk.live/version/customer-shipping/sprint-2.7/diag_ph81QgGGAqCIarrj.html
      notifier.runAsync(
        async () => {
          const deleteTemplateConfirmation = new Promise(resolve => {
            prompt.showConfirmationDelete({
              header: S.CONFIRM_PLEASE,
              message: S.TEMPLATE_DELETE_MESSAGE,
              onConfirm: resolve,
            });
          });

          try {
            await deleteTemplateConfirmation;
            overlay.show();
            const { data } = await deleteShipmentImportTemplateById();

            if (getValue(data, "templateId", null)) {
              const { data: templates } = await fetchShipmentImportTemplates();
              await fetchShipmentImportTemplateById(templates[0].templateId);

              prompt.showInfo({
                header: S.TEMPLATE_DELETED,
                message: S.TEMPLATE_DELETED_MESSAGE,
              });
            }
          } finally {
            overlay.hide();
          }
        },
        {
          entityName: S.DELETE,
        }
      ),
    onClickSave: ({
      overlay,
      dispatch,
      prompt,
      notifier,
      formValues,
      fetchShipmentImportTemplates,
      fetchShipmentImportTemplateById,
      shipmentImportTemplatesKeyValue,
      excludeFields,
      includeFields,
      uiFields,
      showInvisibleFieldError,
    }) =>
      overlay.showWhile(
        notifier.runAsync(
          showInvisibleFieldError(
            async templateName => {
              const message = ImportsModels.getErrorMessage(formValues);
              if (message.length) {
                return prompt.showInfo({
                  header: formatMessage(S.CANNOT_$, "save"),
                  message,
                });
              }
              const { body, id } = ImportsModels.getQuery(
                formValues,
                shipmentImportTemplatesKeyValue,
                templateName
              );
              const data = await dispatch(
                TemplateActions.saveTemplate(
                  body,
                  id,
                  formValues[ImportsFields.DEFINITION]
                )
              );
              dispatch(
                TemplateActions.updateTemplate({
                  ...body,
                  includeFields,
                  excludeFields,
                })
              );
              if (
                formValues[ImportsFields.DEFINITION] ===
                TEMPLATE_TYPE.SHIPMENT.toString()
              ) {
                const { data: templatesList } =
                  await fetchShipmentImportTemplates();

                dispatch(
                  initializeForm(IMPORTS_FORM, {
                    [ImportsFields.TEMPLATE_NAME]: data.templateId,
                    [ImportsFields.DEFAULT_TEMPLATE]: getValue(
                      templatesList?.find(
                        template => template.templateId === data.templateId
                      ),
                      [ImportsFields.DEFAULT_TEMPLATE]
                    ),
                  })
                );
                await fetchShipmentImportTemplateById(data.templateId);
              }
              if (
                formValues[ImportsFields.DEFINITION] ===
                TEMPLATE_TYPE.SHIPMENT_RECEIPT.toString()
              ) {
                dispatch(
                  initializeForm(IMPORTS_FORM, {
                    [ImportsFields.DEFAULT_TEMPLATE]: false,
                  })
                );
                dispatch(TemplateActions.fetchShipmentReceiptTemplate());
              }
              if (
                formValues[ImportsFields.DEFINITION] ===
                TEMPLATE_TYPE.SHIPMENT_EXPORT.toString()
              ) {
                dispatch(TemplateActions.fetchShipmentExportTemplate());
              }
              prompt.showInfo({
                header: S.TEMPLATE_SAVED,
                message: S.TEMPLATE_SAVED_MESSAGE,
              });
            },
            { uiFields }
          ),
          { entityName: S.TEMPLATE }
        )
      ),
    onClickAiImport: ({
      overlay,
      notifier,
      delimiters,
      aiImportToggle,
      setAiInitialData,
      includeFields,
      excludeFields,
      dispatch,
    }) =>
      overlay.showWhile(
        notifier.runAsync(
          async () => {
            const allFields = ImportsModels.getAllFields(
              excludeFields,
              includeFields
            );

            try {
              const { fileHeader, file, ...restFileData } =
                await ImportsModels.getFileData(delimiters, allFields);
              dispatch(ImportsActions.updateAqaFileInput(file)); // NOTE: need only for aqa tests
              const { data: aiResponse } =
                await importsApi.runAiImportMapping(fileHeader);

              setAiInitialData({
                ...restFileData,
                ...ImportsModels.getAiInitialFields({
                  allFields,
                  fileHeader,
                  aiResponse,
                }),
              });
              aiImportToggle.switchOn();
            } catch (err) {
              if (!isAbortError(err)) {
                // NOTE: runAsync throw submission error under the hood
                throw new Error(
                  getValue(err, `errors[0].message`, err.message)
                );
              }
            }
          },
          { entityName: S.AI_SUGGESTED_FIELDS }
        )
      ),
  }),
  withAppLoader({
    loadFn: async ({
      dispatch,
      fetchTemplateTypes,
      fetchDelimiters,
      fetchAddressbookDelimiters,
      fetchTemplate,
      fetchTemplateTranslations,
      fetchProfiles,
      fetchProductbookDelimiters,
    }) => {
      // @see https://it.dpduk.live/version/customer-shipping/sprint-2.1/diag_fh.kfb6GAqAAhTnL.html?id=1644404235662
      const [{ data: templateTypes }] = await Promise.all([
        fetchTemplateTypes(),
        fetchProfiles(),
        fetchDelimiters(),
        fetchAddressbookDelimiters(),
        fetchTemplateTranslations(),
        fetchProductbookDelimiters(),
      ]).then(results =>
        results.map(result => ({
          data: result?.data || [],
        }))
      );

      const typeCode = templateTypes?.[0]?.typeCode.toString();
      const template = await fetchTemplate(typeCode);
      dispatch(
        initializeForm(IMPORTS_FORM, {
          ...(!isEmpty(template) && template),
          [ImportsFields.DEFINITION]: typeCode,
        })
      );
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        dispatch,
        includeFields,
        template,
        setSelectedIncludeFields,
        setSelectedIncludeRowIds,
        setCheckedOverrideWeightToggle,
        setSelectedExcludeRowIds,
      } = this.props;

      if (!isEqual(includeFields, prevProps.includeFields)) {
        // @see https://it.dpduk.live/version/customer-shipping/sprint-2.1/diag_THdPfb6GAqAAhTih.html
        dispatch(
          ImportsActions.updatePrintSequence(
            ImportsModels.createPrintSequence(includeFields)
          )
        );
      }

      if (!isEqual(template, prevProps.template)) {
        dispatch(ImportsActions.updateImportsForm(template));
        setCheckedOverrideWeightToggle(
          !!template[ImportsFields.OVERRIDE_WEIGHT]
        );
        setSelectedIncludeRowIds({});
        setSelectedExcludeRowIds({});
        setSelectedIncludeFields([]);
      }
    },
    componentWillUnmount() {
      this.props.clearPage();
    },
  })
)(Imports);
