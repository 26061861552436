import { useMemo } from "react";

import PropTypes from "prop-types";
import { useRowSelect } from "react-table/src/plugin-hooks/useRowSelect";
import { useSortBy } from "react-table/src/plugin-hooks/useSortBy";
import { DndTable } from "@dpdgroupuk/mydpd-ui";

import { ImportsFields } from "~/constants/forms";
import { ImportsModels } from "~/pages/Imports/models";
import styles from "./DndTables.module.scss";
import TableContainer from "~/pages/Imports/components/AiImportModal/components/TableContainer";

const DndTables = ({
  excludeFields,
  setSelectedExcludeFields,
  selectedExcludeRowIds,
  includeFields,
  templateTranslations,
  setSelectedIncludeFields,
  selectedIncludeRowIds,
  onDragRowEnd,
  selectedExcludeFields,
  selectedIncludeFields,
  getAvailableFieldsFooterActions,
  getIncludeFieldsFooterActions,
  onTranslationChange,
}) => {
  const tableEntities = useMemo(
    () => [
      {
        columns: ImportsModels.AVAILABLE_FIELDS_COLUMNS,
        data: excludeFields,
        droppableId: ImportsFields.EXCLUDE,
        draggableKeyId: "templateFieldId",
        footerActions: getAvailableFieldsFooterActions,
        getSelectedRows: setSelectedExcludeFields,
        initialState: {
          selectedRowIds: selectedExcludeRowIds,
        },
        importCardValues: {
          md: 12,
          lg: 6,
          xl: 3,
        },
        classes: {
          container: styles.availableTableContainer,
          ghostingRow: styles.ghostingRow,
        },
      },
      {
        columns: ImportsModels.AI_SUGGESTED_COLUMNS(
          templateTranslations,
          onTranslationChange,
          {
            group: styles.translationGroup,
            input: styles.translationInput,
            dropdown: styles.translationDropdown,
            option: styles.translationOption,
          }
        ),
        data: includeFields,
        droppableId: ImportsFields.INCLUDE,
        draggableKeyId: "templateFieldId",
        footerActions: getIncludeFieldsFooterActions,
        getSelectedRows: setSelectedIncludeFields,
        initialState: {
          selectedRowIds: selectedIncludeRowIds,
        },
        importCardValues: {
          md: 12,
          lg: 12,
          xl: 6,
          // TODO: pass classes directly (refactor mydpd-ui)
          classes: {
            removeButtons: `d-flex justify-content-lg-start pt-3 ${styles.actionSectionShiftStart}`,
            moveButtons: `d-flex justify-content-end pt-3 ${styles.actionSectionShiftEnd}`,
          },
        },
      },
    ],
    [
      JSON.stringify(excludeFields),
      JSON.stringify(includeFields),
      JSON.stringify(selectedExcludeRowIds),
      JSON.stringify(selectedIncludeRowIds),
      JSON.stringify(selectedExcludeFields),
      JSON.stringify(selectedIncludeFields),
      templateTranslations,
    ]
  );

  return (
    <DndTable
      onDragEnd={onDragRowEnd}
      tableEntities={tableEntities}
      isDraggableTable={true}
      tableHooks={[useSortBy, useRowSelect]}
      container={TableContainer}
      containerId="fields-options-table-container"
      classes={{
        container: styles.tableContainer,
        ghostingRow: styles.ghostingRow,
      }}
    />
  );
};

DndTables.propTypes = {
  onClickRemoveField: PropTypes.func,
  onClickRemoveAllFields: PropTypes.func,
  onClickMoveUp: PropTypes.func,
  onClickMoveDown: PropTypes.func,
  includeFields: PropTypes.array,
  onClickRemoveAll: PropTypes.func,
  templateTranslations: PropTypes.array,
  setSelectedIncludeFields: PropTypes.func,
  onClickRemove: PropTypes.func,
  formValues: PropTypes.object,
  selectedIncludeRowIds: PropTypes.object,
  step: PropTypes.number,
  onClickAddAllFields: PropTypes.func,
  onClickAddField: PropTypes.func,
  excludeFields: PropTypes.array,
  onClickAdd: PropTypes.func,
  onClickAddAll: PropTypes.func,
  setSelectedExcludeFields: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDragRowEnd: PropTypes.func,
  getAvailableFieldsFooterActions: PropTypes.func,
  getIncludeFieldsFooterActions: PropTypes.func,
  onTranslationChange: PropTypes.func,
  selectedExcludeRowIds: PropTypes.object,
  selectedExcludeFields: PropTypes.array,
  selectedIncludeFields: PropTypes.array,
};

export default DndTables;
