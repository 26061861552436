import api from "~/apis/api";
import { IMPORTS, SHIPPING_IMPORTS } from "~/apis/basePaths";

export const createImportJobId = ({ templateId, body, options }) =>
  api.post({
    path: `${IMPORTS}`,
    ...(templateId && {
      query: {
        templateId,
      },
    }),
    body,
    options,
  });

export const runImportJobId = (jobId, data, options) =>
  api.post({
    path: `${IMPORTS}/${jobId}`,
    body: data,
    options,
  });

export const getImportResultByJobId = async (jobId, params, options) => {
  const { data } = await api.get({
    path: `${SHIPPING_IMPORTS}/${jobId}/logs`,
    query: params,
    options,
  });
  return data;
};

export const getFailedImportResultByJobId = async jobId => {
  const { data } = await api.get({
    path: `${SHIPPING_IMPORTS}/${jobId}/errors`,
  });
  return data;
};

export const getImportReceiptByJobId = async (jobId, query = {}) => {
  const { data } = await api.get({
    path: `${SHIPPING_IMPORTS}/${jobId}/receipts`,
    query,
  });
  return data;
};

export const getImportLabelsByJobId = async (jobId, query = {}, options) => {
  const { data } = await api.get({
    path: `${SHIPPING_IMPORTS}/${jobId}/labels`,
    query,
    options,
  });

  return data;
};

export const completeWorkstationJob = jobId =>
  api.put({
    path: `${SHIPPING_IMPORTS}/${jobId}/prints`,
  });

export const getImportReceiptsByShipmentIds = async shipmentIds => {
  const { data } = await api.get({
    path: `${SHIPPING_IMPORTS}/receipts`,
    query: {
      filterBy: "shipmentIds",
      shipmentIds,
    },
  });
  return data;
};

export const getReceiptByShipmentDate = async shipmentDate => {
  const { data } = await api.get({
    path: `${SHIPPING_IMPORTS}/receipts`,
    query: {
      filterBy: "shipmentDate",
      ...(shipmentDate && { shipmentDate }),
    },
  });
  return data;
};

export const getJobsForWorkstation = async workstationId => {
  const { data } = await api.get({
    path: `${SHIPPING_IMPORTS}/prints/${workstationId}`,
  });
  return data;
};

export const runAiImportMapping = (data, options) =>
  api.post({
    path: `${SHIPPING_IMPORTS}/mapping`,
    body: data,
    options,
  });
