import PropTypes from "prop-types";
import { DndTable } from "@dpdgroupuk/mydpd-ui";
import React from "react";
import * as S from "~/constants/strings";
import styles from "./AiImportModal.module.scss";
import { Col, Row } from "react-bootstrap";
import { ImportsModels } from "~/pages/Imports/models";
import DndTables from "~/pages/Imports/components/AiImportModal/components/DndTables";
import { compose, lifecycle, withState } from "recompose";
import withDndTableActions from "~/pages/Imports/hocs/withDndTableActions";
import isEqual from "lodash/isEqual";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import AiModal from "~/pages/Imports/components/AiModal";
import isEmpty from "lodash/isEmpty";
import { ImportsFields } from "~/constants/forms";

const AiImportModal = props => {
  const {
    aiImportToggle,
    aiApplyToggle,
    importedFields,
    onAiBackClick,
    onCancelAiSuggestion,
    onSubmitAiSuggestion,
    onApplyAiSuggestion,
  } = props;

  return (
    <>
      <AiModal
        id={"ai-import-file-modal"}
        classes={{ modalContainer: styles.modalContainer }}
        open={aiImportToggle.value}
        onSubmit={onSubmitAiSuggestion}
        onCancel={onCancelAiSuggestion}
      >
        {
          <Row>
            <Col md={12} lg={6} xl={3} className={styles.importedFields}>
              <DndTable
                columns={ImportsModels.IMPORTED_FIELD_COLUMNS}
                data={importedFields}
                classes={{
                  container: styles.tableContainer,
                }}
                initialState={{
                  storageKey: "importedFields",
                }}
              />
            </Col>
            <DndTables {...props} />
          </Row>
        }
      </AiModal>
      <AiModal
        size={"md"}
        open={aiApplyToggle.value}
        onCancel={onAiBackClick}
        onSubmit={onApplyAiSuggestion}
        children={S.SAVE_AI_SUGGESTION}
        confirmButtonText={S.APPLY}
        closeButtonText={S.BACK}
      />
    </>
  );
};

AiImportModal.propTypes = {
  open: PropTypes.bool,
  importedFields: PropTypes.array,
  templateTranslations: PropTypes.array,
  aiInitialData: PropTypes.object,
  onAiBackClick: PropTypes.func,
  onCancelAiSuggestion: PropTypes.func,
  onSubmitAiSuggestion: PropTypes.func,
  onApplyAiSuggestion: PropTypes.func,
  updateImportForm: PropTypes.func,
  setAiInitialData: PropTypes.func,
  onTranslationChange: PropTypes.func,
};

export default compose(
  withState("importedFields", "setImportedFields", []),
  withState("excludeFields", "setExcludeFields", []),
  withState("includeFields", "setIncludeFields", []),
  connect(
    null,
    (
      dispatch,
      {
        aiImportToggle,
        aiApplyToggle,
        setImportedFields,
        setExcludeFields,
        setIncludeFields,
        includeFields,
        excludeFields,
        updateImportForm,
        setAiInitialData,
        aiInitialData,
      }
    ) => ({
      updateExcludeIncludeFields: ({ excludeFields, includeFields }) => {
        setExcludeFields(excludeFields);
        setIncludeFields(includeFields);
      },
      updateIncludeFields: includeFields => setIncludeFields(includeFields),
      onTranslationChange: (value, index) => {
        const newIncludeFields = cloneDeep(includeFields);
        set(newIncludeFields, [index, "templateFieldTranslationCode"], value);
        setIncludeFields(newIncludeFields);
      },
      onAiBackClick: () => {
        aiApplyToggle.switchOff();
        aiImportToggle.switchOn();
      },
      onCancelAiSuggestion: () => {
        aiImportToggle.switchOff();
        setAiInitialData({});
      },
      onSubmitAiSuggestion: () => {
        aiImportToggle.switchOff();
        aiApplyToggle.switchOn();
      },
      onApplyAiSuggestion: () => {
        updateImportForm({
          excludeFields,
          includeFields,
          [ImportsFields.EXTENSION]: aiInitialData.fileExtension,
          [ImportsFields.DELIMITER]: aiInitialData.delimiter,
        });
        setImportedFields([]);
        setExcludeFields([]);
        setIncludeFields([]);
        setAiInitialData({});
        aiApplyToggle.switchOff();
      },
    })
  ),
  withDndTableActions,
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        aiInitialData,
        setImportedFields,
        setExcludeFields,
        setIncludeFields,
      } = this.props;

      if (
        !isEmpty(aiInitialData) &&
        !isEqual(aiInitialData, prevProps.aiInitialData)
      ) {
        const { importedFields, excludeFields, includeFields } = aiInitialData;
        setImportedFields(importedFields);
        setExcludeFields(excludeFields);
        setIncludeFields(includeFields);
      }
    },
  })
)(AiImportModal);
