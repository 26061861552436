import PropTypes from "prop-types";
import { Button, Modal } from "@dpdgroupuk/mydpd-ui";
import React from "react";
import * as S from "~/constants/strings";
import { AiImportTitle } from "~/components/Icon";
import styles from "./AiModal.module.scss";

const AiModal = ({
  id,
  confirmButtonText,
  closeButtonText,
  size,
  children,
  open,
  onCancel,
  onSubmit,
  classes,
}) => (
  <Modal
    id={id}
    dialogClassName={classes.modalContainer}
    show={open}
    onHide={onCancel}
    size={size}
  >
    <Modal.Header onHide={onCancel}>
      <Modal.Title>
        <AiImportTitle />
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>{children}</Modal.Body>
    <Modal.Footer>
      <Button variant="dark" onClick={onCancel}>
        {closeButtonText}
      </Button>
      <Button className={styles.submit} onClick={onSubmit}>
        {confirmButtonText}
      </Button>
    </Modal.Footer>
  </Modal>
);

AiModal.defaultProps = {
  classes: {},
  size: "lg",
  confirmButtonText: S.SUBMIT,
  closeButtonText: S.CANCEL,
};

AiModal.propTypes = {
  id: PropTypes.string,
  confirmButtonText: PropTypes.string,
  closeButtonText: PropTypes.string,
  size: PropTypes.string,
  open: PropTypes.bool,
  classes: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
};

export default AiModal;
