import { get, isEmpty, merge, pick, toNumber } from "lodash";
import moment from "moment";

import * as DATE_FORMAT from "~/constants/dateFormats";
import {
  AddExtraLabelEntity,
  ExporterDetailsEntity,
  Fields,
  ImporterDetailsEntity,
  ProductDescriptionsEntity,
  ProductEntity,
  ShipmentEntity,
  TemplatesEntity,
} from "~/constants/forms";
import * as S from "~/constants/strings";
import {
  AddressModels,
  ServiceModels,
  SettingsModels,
  ShipmentModels,
} from "~/models";
import {
  isReverseItShipmentType,
  isSwapItOrReverseItShipmentType,
} from "~/models/shipment/shipment";
import { REGEX } from "~/models/validators/index";
import { isWeekend } from "~/utils/date";
import { getDeepKeys, getValue } from "~/utils/object";
import { formatMessage } from "~/utils/string";
import { SHIP_TO_SHOP } from "~/constants/services";

export const createErrorBody = (path, message) => ({
  path,
  message,
});

export const returnTotalWeightValidation = (props, joiValidationErrors) => {
  const totalWeightValue = get(
    props,
    `values.${ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT}`
  );
  const numberOfParcels = get(
    props,
    `values.${ShipmentEntity.INBOUND_CONSIGNMENT.NUMBER_OF_PARCELS}`
  );
  if (!totalWeightValue || !numberOfParcels) {
    return;
  }

  if (
    get(joiValidationErrors, ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT) &&
    get(
      joiValidationErrors,
      ShipmentEntity.INBOUND_CONSIGNMENT.NUMBER_OF_PARCELS
    )
  ) {
    return;
  }

  if (totalWeightValue / numberOfParcels > 30) {
    return createErrorBody(
      ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT,
      S.WEIGHT_PER_PARCEL_ERROR
    );
  }
};

export const editTotalNumberOfPackages = (
  props,
  {
    fieldPath = ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
    parcelsPath = ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS,
    direction = S.CONSIGNMENT_DIRECTION.OUTBOUND,
  } = {}
) => {
  const numberOfParcels = get(props, `values.${fieldPath}`);
  const shipmentNumberOfParcels = get(props, `shipment.${fieldPath}`);
  const isVoidedParcel = get(props, `shipment.${parcelsPath}`, []).some(
    ({ isVoided }) => isVoided
  );

  if (!shipmentNumberOfParcels || !numberOfParcels) {
    return;
  }

  if (numberOfParcels < shipmentNumberOfParcels) {
    return createErrorBody(
      fieldPath,
      formatMessage(
        S.EDIT_NUMBER_OF_PACKAGES_ERROR_$_CANNOT_$,
        direction,
        S.DECREASED
      )
    );
  }

  if (numberOfParcels > shipmentNumberOfParcels && isVoidedParcel) {
    return createErrorBody(fieldPath, S.UNVOID_TO_PROCEED);
  }
};

export const submissionErrorFirst = (props, joiValidationErrors) => {
  const submitErrors =
    props.createShipmentSubmitErrors || props.editShipmentSubmitErrors;

  if (submitErrors) {
    const equalErrors = pick(submitErrors, getDeepKeys(joiValidationErrors));

    merge(joiValidationErrors, pick(submitErrors, getDeepKeys(equalErrors)));
  }
};

export const countryValidation = ({ countries, values }, fieldPath) => {
  const countryValue = get(values, fieldPath);

  const countryMatch = countries.find(
    ({ label, value }) =>
      label.toUpperCase() === countryValue?.toUpperCase() ||
      value.toUpperCase() === countryValue?.toUpperCase()
  );

  if (isEmpty(countryMatch)) {
    return createErrorBody(fieldPath, S.INVALID_COUNTRY_ENTERED);
  }
};

// @see: comments from https://geopost.jira.com/browse/CSHIP-6394
export const invoiceImporterCountryValidation = (
  {
    countries,
    values,
    selectedDeliveryCountry,
    generateCustomsData,
    allowedFields,
  },
  fieldPath
) => {
  if (
    !generateCustomsData ||
    !getValue(allowedFields, "exporterImporterDetails", true) // NOTE: default true for EditImporterModal validation
  )
    return;

  let errors = countryValidation({ countries, values }, fieldPath);

  if (
    get(values, fieldPath) === S.GB &&
    selectedDeliveryCountry.countryKey !== S.GB
  ) {
    errors = { ...errors, ...createErrorBody(fieldPath, S.CANNOT_BE_UK) };
  }

  return errors;
};

export const parcelCountryValidation = ({ countries, values }) => {
  const errors = [];
  const parcels = get(values, "outboundConsignment.parcels", []);

  parcels.forEach((parcel, parcelIndex) => {
    const products = get(parcel, "products", []);

    products.forEach((product, productIndex) => {
      if (product.countryOfOrigin) {
        const error = countryValidation(
          { countries, values },
          `outboundConsignment.parcels[${parcelIndex}].products[${productIndex}].countryOfOrigin`
        );

        if (error) {
          errors.push(error);
        }
      }
    });
  });

  if (errors.length) {
    return errors;
  }
};

export const totalNewParcelsValidation = (
  { numberOfParcels, values, outboundTotalWeight, shipmentType },
  fieldPath
) => {
  const numberOfNewParcels = get(values, fieldPath);
  const totalParcels = toNumber(numberOfNewParcels) + toNumber(numberOfParcels);
  const totalWeightOfNewParcels = get(
    values,
    AddExtraLabelEntity.TOTAL_NEW_PARCELS_WEIGHT
  );
  const totalWeight =
    toNumber(totalWeightOfNewParcels) + toNumber(outboundTotalWeight);

  if (totalParcels > 99) {
    return createErrorBody(fieldPath, S.TOTAL_NEW_PARCELS_ERROR);
  }

  if (
    isReverseItShipmentType(shipmentType) &&
    totalWeight / totalParcels > 30
  ) {
    return createErrorBody(
      AddExtraLabelEntity.TOTAL_NEW_PARCELS_WEIGHT,
      S.WEIGHT_PER_PARCEL_ERROR
    );
  }
};

export const filePathValidation = values => {
  const filePath = values[TemplatesEntity.EXPORT_FILE];
  if (!filePath?.match(REGEX.FILE_EXTENSION_3_CHARACTERS)) {
    return createErrorBody(
      [TemplatesEntity.EXPORT_FILE],
      S.ADD_3_CHARACTERS_EXTENSION
    );
  }
};

export const packageNumberValidation = (
  packageNumber,
  numberOfParcels,
  fieldPath
) => {
  if (!isEmpty(packageNumber) && parseInt(packageNumber) > numberOfParcels) {
    return createErrorBody(
      fieldPath,
      formatMessage(S.$_MUST_BE_ENTERED, S.PACKAGE_NUMBER)
    );
  }
};

export const validateShipmentDate = (
  { preferences, values, advancedCustomerConfiguration },
  fieldPath
) => {
  const enabledWeekend = get(
    preferences,
    "shippingDefaults.enableWeekend",
    false
  );
  const { maxDate } = ShipmentModels.getAvailableDateRange(
    enabledWeekend,
    getValue(advancedCustomerConfiguration, "forwardDateOver7Days", false)
  );

  if (
    !enabledWeekend &&
    isWeekend(
      moment(values[fieldPath], DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT).toDate()
    )
  ) {
    return createErrorBody(fieldPath, S.DATE_IS_NON_WORKING_DAY);
  }

  const isShipmentDateAfterMaxDate = moment(
    values[fieldPath],
    DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT
  ).isAfter(moment(maxDate, DATE_FORMAT.ISO_DATE_FORMAT));

  if (isShipmentDateAfterMaxDate) {
    return createErrorBody(
      fieldPath,
      S.SHIPMENT_DATE_GREATER_THAN_5_DAYS_IN_FUTURE
    );
  }
};

export const outboundTotalWeightValidation = (props, joiValidationErrors) => {
  if (
    isSwapItOrReverseItShipmentType(
      getValue(props.values, ShipmentEntity.SHIPMENT_TYPE)
    )
  ) {
    const totalWeightValue = getValue(
      props.values,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT
    );
    const numberOfParcels = getValue(
      props.values,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS
    );

    if (!totalWeightValue || !numberOfParcels) {
      return;
    }

    if (
      get(
        joiValidationErrors,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT
      ) &&
      get(
        joiValidationErrors,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS
      )
    ) {
      return;
    }

    if (totalWeightValue / numberOfParcels > 30) {
      return createErrorBody(
        ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT,
        S.WEIGHT_PER_PARCEL_ERROR
      );
    }
  }
};

export const validateCommodityStatic = ({
  isAdditionalCommCodeCheckRequired,
  values,
  selectedService,
}) => {
  const commodityCode = getValue(values, ProductEntity.COMMODITY_CODE, "");

  if (isAdditionalCommCodeCheckRequired || !commodityCode) {
    return;
  }

  if (ServiceModels.isDpdDirectService(selectedService)) {
    if (!REGEX.NUMERICAL_ONLY.test(commodityCode)) {
      return createErrorBody(
        ProductEntity.COMMODITY_CODE,
        "Commodity code have invalid type"
      );
    }

    if (commodityCode.length !== 8) {
      return createErrorBody(
        ProductEntity.COMMODITY_CODE,
        "Commodity code is invalid, must be 8 digits"
      );
    }
  }
};

// @see:https://it.dpduk.live/version/customer-shipping/ni-protocol-shipping/sprint-2/diag_lzni6AmGAqCIn7Rc.html
const validateUkimsNumber = (
  shippingVersion,
  selectedService,
  {
    checkedUkimsNumber,
    relatedUkimsNumber,
    countryCode,
    postcode,
    isBusiness,
    atRisk,
    fieldPath,
    errorMessage = formatMessage(
      S.$_OR_$_MUST_BE_PROVIDED,
      formatMessage(S.$_$_NUMBER, S.EXPORTERS, S.UKIMS),
      formatMessage(S.$_$_NUMBER, S.IMPORTERS, S.UKIMS)
    ),
  }
) => {
  if (
    !SettingsModels.isNewVersion(shippingVersion) ||
    checkedUkimsNumber.length
  ) {
    return;
  }

  if (
    AddressModels.isNiShipment(countryCode, postcode) &&
    isBusiness &&
    !atRisk &&
    !relatedUkimsNumber.length &&
    ServiceModels.isFlagRequired(selectedService, "ukimsRequired")
  ) {
    return createErrorBody(fieldPath, errorMessage);
  }
};

export const exporterUkimsValidation = ({
  formValues,
  createShipmentValues,
  shippingVersion,
  selectedService,
}) =>
  validateUkimsNumber(shippingVersion, selectedService, {
    checkedUkimsNumber: getValue(
      formValues,
      ExporterDetailsEntity.UKIMS_NUMBER,
      ""
    ),
    relatedUkimsNumber: getValue(
      createShipmentValues,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.UKIMS_NUMBER,
      ""
    ),
    countryCode: getValue(
      createShipmentValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
      ""
    ),
    postcode: getValue(
      createShipmentValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
      ""
    ),
    isBusiness: getValue(
      createShipmentValues,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
      false
    ),
    atRisk: getValue(
      createShipmentValues,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.AT_RISK,
      false
    ),
    fieldPath: ExporterDetailsEntity.UKIMS_NUMBER,
  });

export const invoiceExporterUkimsValidation = ({
  createShipmentValues,
  selectedService,
  customer,
}) =>
  validateUkimsNumber(customer.shippingVersion, selectedService, {
    checkedUkimsNumber: getValue(
      createShipmentValues,
      ShipmentEntity.INVOICE.EXPORTER_DETAILS.UKIMS_NUMBER,
      ""
    ),
    relatedUkimsNumber: getValue(
      createShipmentValues,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.UKIMS_NUMBER,
      ""
    ),
    countryCode: getValue(
      createShipmentValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
      ""
    ),
    postcode: getValue(
      createShipmentValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
      ""
    ),
    isBusiness: getValue(
      createShipmentValues,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
      false
    ),
    atRisk: getValue(
      createShipmentValues,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.AT_RISK,
      false
    ),
    fieldPath: ShipmentEntity.INVOICE.EXPORTER_DETAILS.UKIMS_NUMBER,
  });

export const importerUkimsValidation = ({
  formValues,
  createShipmentValues,
  shippingVersion,
  selectedService,
}) =>
  validateUkimsNumber(shippingVersion, selectedService, {
    checkedUkimsNumber: getValue(
      formValues,
      ImporterDetailsEntity.UKIMS_NUMBER,
      ""
    ),
    relatedUkimsNumber: getValue(
      createShipmentValues,
      ShipmentEntity.INVOICE.EXPORTER_DETAILS.UKIMS_NUMBER,
      ""
    ),
    countryCode: getValue(
      createShipmentValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
      ""
    ),
    postcode: getValue(
      createShipmentValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
      ""
    ),
    isBusiness: getValue(formValues, ImporterDetailsEntity.IS_BUSINESS, false),
    atRisk: getValue(formValues, ImporterDetailsEntity.AT_RISK, false),
    fieldPath: ImporterDetailsEntity.UKIMS_NUMBER,
    errorMessage: formatMessage(
      S.$_OR_$_MUST_BE_PROVIDED,
      formatMessage(S.$_$_NUMBER, S.IMPORTERS, S.UKIMS),
      formatMessage(S.$_$_NUMBER, S.EXPORTERS, S.UKIMS)
    ),
  });

export const invoiceImporterUkimsValidation = ({
  createShipmentValues,
  selectedService,
  customer,
}) =>
  validateUkimsNumber(customer.shippingVersion, selectedService, {
    checkedUkimsNumber: getValue(
      createShipmentValues,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.UKIMS_NUMBER,
      ""
    ),
    relatedUkimsNumber: getValue(
      createShipmentValues,
      ShipmentEntity.INVOICE.EXPORTER_DETAILS.UKIMS_NUMBER,
      ""
    ),
    countryCode: getValue(
      createShipmentValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
      ""
    ),
    postcode: getValue(
      createShipmentValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
      ""
    ),
    isBusiness: getValue(
      createShipmentValues,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
      false
    ),
    atRisk: getValue(
      createShipmentValues,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.AT_RISK,
      false
    ),
    fieldPath: ShipmentEntity.INVOICE.IMPORTER_DETAILS.UKIMS_NUMBER,
    errorMessage: formatMessage(
      S.$_OR_$_MUST_BE_PROVIDED,
      formatMessage(S.$_$_NUMBER, S.IMPORTERS, S.UKIMS),
      formatMessage(S.$_$_NUMBER, S.EXPORTERS, S.UKIMS)
    ),
  });

//@see: https://it.dpduk.live/version/customer-shipping/ni-protocol-shipping/sprint-2/diag_v0aK2V6GAqAADLEJ.html
const validateEori = (
  shippingVersion,
  { eoriNumber, ukimsNumber, isBusiness, fieldPath }
) => {
  if (!SettingsModels.isNewVersion(shippingVersion) || eoriNumber.length) {
    return;
  }

  if (isBusiness && ukimsNumber.length) {
    return createErrorBody(
      fieldPath,
      formatMessage(
        S.$_MUST_BE_PROVIDED_WITH_$,
        formatMessage(S.$_$_NUMBER, S.IMPORTERS, S.EORI),
        formatMessage(S.$_$_NUMBER, S.IMPORTERS, S.UKIMS)
      )
    );
  }
};

export const importerEoriValidation = ({ formValues, shippingVersion }) =>
  validateEori(shippingVersion, {
    eoriNumber: getValue(formValues, ImporterDetailsEntity.EORI_NUMBER, ""),
    ukimsNumber: getValue(formValues, ImporterDetailsEntity.UKIMS_NUMBER, ""),
    isBusiness: getValue(formValues, ImporterDetailsEntity.IS_BUSINESS, false),
    fieldPath: ImporterDetailsEntity.EORI_NUMBER,
  });

export const invoiceImporterEoriValidation = ({
  createShipmentValues,
  customer,
}) =>
  validateEori(customer.shippingVersion, {
    eoriNumber: getValue(
      createShipmentValues,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.EORI_NUMBER,
      ""
    ),
    ukimsNumber: getValue(
      createShipmentValues,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.UKIMS_NUMBER,
      ""
    ),
    isBusiness: getValue(
      createShipmentValues,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
      false
    ),
    fieldPath: ShipmentEntity.INVOICE.IMPORTER_DETAILS.EORI_NUMBER,
  });

export const parcelValidation = props => {
  const { allowedFields, createShipmentValues } = props;

  if (
    (allowedFields.productDetailsData ||
      allowedFields[ProductDescriptionsEntity.PRODUCT_DESCRIPTION]) &&
    (!getValue(
      createShipmentValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS,
      []
    ).length ||
      !getValue(
        createShipmentValues,
        `${ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS}[0].products`,
        []
      ).length)
  ) {
    return createErrorBody(
      ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS,
      Fields.PARCELS
    );
  }
};

// @see: https://it.dpduk.live/it/diagram/diag_VRi2_sGGAqCIaza8.html
export const outboundServiceValidation = ({ values }) => {
  if (
    SHIP_TO_SHOP.includes(
      getValue(values, ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE, "")
    )
  ) {
    return createErrorBody(
      ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE,
      S.SHIP2SHOP_SHIPMENTS_CANNOT_BE_EDITED
    );
  }
};
