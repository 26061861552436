import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import styles from "./TableContainer.module.scss";

const TableContainer = ({
  importCardValues,
  footerActions: FooterActions,
  children,
  containerId,
}) => {
  const { xl, lg, md, classes } = importCardValues;

  return (
    <Col key={containerId} md={md} lg={lg} xl={xl} className={styles.column}>
      <Col className="col-12 px-0" id={containerId}>
        {children}
      </Col>
      <Col className={"col-12 d-flex justify-content-sm-end py-2 px-0"}>
        <FooterActions containerId={containerId} classes={classes} />
      </Col>
    </Col>
  );
};

TableContainer.propTypes = {
  step: PropTypes.number,
  children: PropTypes.node,
  headerActions: PropTypes.elementType,
  footerActions: PropTypes.elementType,
  importCardValues: PropTypes.object,
  containerId: PropTypes.string,
};

export default TableContainer;
