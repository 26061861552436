export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // email address
export const FIRST_TWO_CHARACTERS = /^[a-zA-Z]{2}[\s\S]*$/;
export const BUSINESS_EORI_REGEX = /^(GBUNREG)$|^((GB)\d{12})$/i;
export const CONSUMER_EXPORTER_EORI_NUMBER = /^(GBUNREG)$|^(.{14})$/i;
export const DD_MM_YYYY_REGEX =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$/;
export const FILE_EXTENSION_3_CHARACTERS = /\.[A-Za-z]{3}$/;
export const UNIX_FILE_NAME =
  /(?!.{256,})[^ /][ .\w-$()+=[\];#@~,&'^`!%{}<>|]+[^. ]$/i;
export const WINDOWS_FILE_NAME =
  /(?!.{256,})(?!(aux|clock\$|con|nul|prn|com[1-9]|lpt[1-9])(?:$|\.))[^ \\][ .\w-$()+=[\];#@~,&'^`!%{}]+[^. ]$/i;
export const UNIX_FILE_PATH = /^\/(?:[^/]+\/)*[^/:*?"<>|]+\//;
export const WINDOWS_FILE_PATH = /^[a-zA-Z]:\\([^\\/:*?"<>|]+\\)*/;
export const NUMERICAL_ONLY = /^[0-9]+$/; // numerical chars only
