import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";

import { Button, FormControl } from "@dpdgroupuk/mydpd-ui";

import { IMPORT_TEMPLATE_FORM, ImportsFields } from "~/constants/forms";
import * as S from "~/constants/strings";
import importTemplateFileSchema from "~/models/validators/importTemplateFileSchema";
import styles from "./FieldImportExportFile.module.scss";
import createValidator from "~/utils/joiReduxForm";
import { formatMessage } from "~/utils/string";
import { AiImportButton } from "~/components/Icon";
import classNames from "classnames";

const FieldImportExportFile = ({
  handleSubmit,
  onClickImportTemplate,
  onClickAiImport,
  exportCsvLink,
  disableExport,
  isAiImportAllowed,
}) => (
  <Row>
    <Col xs={6}>
      <Field
        label={S.SELECT_FILE}
        component={FormControl.Input}
        type="file"
        accept=".csv"
        name={ImportsFields.TEMPLATE_FILE}
        onBlur={event => {
          event.preventDefault(); // used to avoid setting empty object to store when prompt window was opened
        }}
      />
    </Col>
    <Col xs={6}>
      <Button
        className={styles.templateBtn}
        onClick={handleSubmit(onClickImportTemplate)}
        variant="primary"
      >
        {formatMessage(S._$_TEMPLATE, S.IMPORT)}
      </Button>
      <Button
        className={styles.templateBtn}
        href={exportCsvLink}
        variant="secondary"
        disabled={disableExport}
      >
        {formatMessage(S._$_TEMPLATE, S.EXPORT)}
      </Button>
      {isAiImportAllowed && (
        <Button
          onClick={onClickAiImport}
          className={classNames(
            "p-0 flex items-center justify-center",
            styles.aiButton
          )}
        >
          <AiImportButton />
        </Button>
      )}
    </Col>
    <Col xs={12} className={styles.aqaFileInput}>
      <Field
        component={FormControl.Input}
        type="file"
        name={ImportsFields.AQA_FILE_INPUT}
        id="aqaFileInput"
        accept=".csv,.txt,.xls,.xlsx"
      />
    </Col>
  </Row>
);

FieldImportExportFile.propTypes = {
  handleSubmit: PropTypes.func,
  onClickImportTemplate: PropTypes.func,
  onClickAiImport: PropTypes.func,
  exportCsvLink: PropTypes.string,
  disableExport: PropTypes.bool,
  isAiImportAllowed: PropTypes.bool,
};

export default reduxForm({
  form: IMPORT_TEMPLATE_FORM,
  shouldError: () => true,
  forceUnregisterOnUnmount: true,
  validate: createValidator(importTemplateFileSchema),
})(FieldImportExportFile);
