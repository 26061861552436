import { compose, withHandlers, withState } from "recompose";
import { ImportsFields } from "~/constants/forms";
import { ImportsModels } from "~/pages/Imports/models";
import { isEmpty } from "lodash/lang";
import { scrollToSelected } from "~/pages/Imports/utils";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { Button } from "@dpdgroupuk/mydpd-ui";
import * as S from "~/constants/strings";

export default compose(
  withState("selectedExcludeFields", "setSelectedExcludeFields", []),
  withState("selectedIncludeFields", "setSelectedIncludeFields", []),
  withState("selectedExcludeRowIds", "setSelectedExcludeRowIds", {}),
  withState("selectedIncludeRowIds", "setSelectedIncludeRowIds", {}),
  connect(
    null,
    (
      dispatch,
      {
        excludeFields,
        includeFields,
        selectedExcludeFields,
        selectedIncludeFields,
        setSelectedIncludeRowIds,
        setSelectedExcludeRowIds,
        updateExcludeIncludeFields,
        updateIncludeFields,
      }
    ) => ({
      onClickAdd: () => {
        const data = ImportsModels.setupSelectedFields(
          excludeFields,
          includeFields,
          selectedExcludeFields
        );

        !isEmpty(selectedExcludeFields) && updateExcludeIncludeFields(data);
        // preserve selection in include fields after adding to include
        setSelectedIncludeRowIds(
          ImportsModels.setupSelectedRowIds(
            selectedExcludeFields,
            data.includeFields
          )
        );
      },
      onClickAddAll: () => {
        if (!isEmpty(excludeFields)) {
          updateExcludeIncludeFields(
            ImportsModels.setupAllFields(excludeFields, includeFields)
          );
          setSelectedExcludeRowIds({});
          setSelectedIncludeRowIds({});
        }
      },
      onClickRemove: () => {
        const data = ImportsModels.setupRemovedSelectedFields(
          excludeFields,
          includeFields,
          selectedIncludeFields
        );
        updateExcludeIncludeFields(data);
        // preserve selection in exclude fields after removing from include
        setSelectedExcludeRowIds(
          ImportsModels.setupSelectedRowIds(
            selectedIncludeFields,
            data.excludeFields
          )
        );
        // cleanup selected include fields
        setSelectedIncludeRowIds(
          ImportsModels.setupSelectedRowIds(
            selectedIncludeFields,
            data.includeFields
          )
        );
      },
      onClickRemoveAll: () => {
        updateExcludeIncludeFields(
          ImportsModels.setupRemovedAllFields(excludeFields, includeFields)
        );
        setSelectedIncludeRowIds({});
        setSelectedExcludeRowIds({});
      },
      onDragEnd: result => {
        const { destination, source } = result;
        const sourceTableId = source.droppableId;
        const destinationTableId = destination.droppableId;
        const toIncludeTable = destinationTableId === ImportsFields.INCLUDE;

        // Drag and drop inside include fields table
        if (sourceTableId === destinationTableId && toIncludeTable) {
          const isIncludeRowSelected = selectedIncludeFields.find(
            item => item.templateFieldId === result.draggableId
          );
          const movedFields = isIncludeRowSelected
            ? selectedIncludeFields
            : includeFields.slice(source.index, source.index + 1);
          const movedRowIds = ImportsModels.setupSelectedRowIds(
            movedFields,
            includeFields
          );

          const reorderedData = ImportsModels.reorderTable(
            includeFields,
            movedFields,
            movedRowIds,
            destination.index,
            source.index
          );

          setSelectedIncludeRowIds(
            ImportsModels.setupSelectedRowIds(movedFields, reorderedData)
          );
          updateIncludeFields(reorderedData);
        } else {
          // Drag and drop to include fields table from exclude fields
          if (toIncludeTable) {
            const isSelected = selectedExcludeFields.find(
              item => item.templateFieldId === result.draggableId
            );
            const draggedRow = excludeFields.find(
              item => item.templateFieldId === result.draggableId
            );
            const selectedFields = isSelected
              ? selectedExcludeFields
              : [draggedRow];
            const {
              excludeFields: newExcludeFields = [],
              includeFields: newIncludeFields = [],
            } = ImportsModels.setupDraggedExcludeFields(
              excludeFields,
              includeFields,
              selectedFields,
              destination
            );

            // preserve selection after drag and drop
            setSelectedIncludeRowIds(
              ImportsModels.setupSelectedRowIds(
                selectedFields,
                newIncludeFields
              )
            );

            if (!isSelected && selectedExcludeFields.length) {
              // preserve selected exclude rows with new indexes
              setSelectedExcludeRowIds(
                ImportsModels.setupSelectedRowIds(
                  selectedExcludeFields,
                  newExcludeFields
                )
              );
            } else {
              setSelectedExcludeRowIds({});
            }

            return updateExcludeIncludeFields({
              [ImportsFields.EXCLUDE]: newExcludeFields,
              [ImportsFields.INCLUDE]: newIncludeFields,
            });
          }

          let data;
          const dragFieldFromInclude = sourceTableId === ImportsFields.INCLUDE;

          // Drag and drop from include fields table to exclude
          if (dragFieldFromInclude) {
            const isSelected = selectedIncludeFields.find(
              item => item.templateFieldId === result.draggableId
            );
            const movedIncludeFields = isSelected
              ? selectedIncludeFields
              : includeFields.slice(source.index, source.index + 1);

            data = ImportsModels.setupRemovedSelectedFields(
              excludeFields,
              includeFields,
              movedIncludeFields
            );

            // preserve selection after drag and drop
            setSelectedExcludeRowIds(
              ImportsModels.setupSelectedRowIds(
                movedIncludeFields,
                data.excludeFields
              )
            );
            setSelectedIncludeRowIds(
              ImportsModels.setupSelectedRowIds(
                selectedIncludeFields,
                data.includeFields
              )
            );
          } else {
            data = ImportsModels.setupRemovedSelectedFields(
              excludeFields,
              includeFields,
              []
            );

            // preserve selection after drag and drop
            setSelectedExcludeRowIds(
              ImportsModels.setupSelectedRowIds(
                selectedExcludeFields,
                data.excludeFields
              )
            );
          }

          updateExcludeIncludeFields(data);
        }
      },
    })
  ),
  withHandlers(() => {
    const handlers = {
      onClickMoveUp:
        ({ includeFields, selectedIncludeFields, onDragEnd }) =>
        containerId => {
          const index = includeFields.indexOf(selectedIncludeFields[0]);

          if (index > 0) {
            onDragEnd({
              draggableId: selectedIncludeFields[0]?.templateFieldId,
              source: {
                droppableId: "includeFields",
                index,
              },
              destination: {
                droppableId: "includeFields",
                index: index - 1,
              },
            });

            // false value for moving up
            scrollToSelected(containerId, false);
          }
        },
      onClickMoveDown:
        ({ includeFields, selectedIncludeFields, onDragEnd }) =>
        containerId => {
          const index = includeFields.indexOf(
            selectedIncludeFields[selectedIncludeFields.length - 1]
          );

          if (index >= 0 && index !== includeFields.length - 1) {
            onDragEnd({
              draggableId:
                selectedIncludeFields[selectedIncludeFields.length - 1]
                  ?.templateFieldId,
              source: {
                droppableId: "includeFields",
                index,
              },
              destination: {
                droppableId: "includeFields",
                index: index + 1,
              },
            });

            // true value for moving down
            scrollToSelected(containerId, true);
          }
        },
      onDragRowEnd:
        ({ onDragEnd }) =>
        result => {
          const { destination, reason, source } = result;
          const dragToSamePlace =
            source.droppableId === destination.droppableId &&
            source.index === destination.index;

          if (!destination || reason === "CANCEL" || dragToSamePlace) {
            return;
          }

          onDragEnd(result);
        },
      getAvailableFieldsFooterActions:
        ({ selectedExcludeFields, onClickAdd, onClickAddAll }) =>
        () => (
          <Row>
            <Col className="pt-3 pl-0">
              <Button
                variant="dark"
                onClick={onClickAdd}
                disabled={
                  !selectedExcludeFields?.length ||
                  selectedExcludeFields?.length === 0
                }
              >
                {S.ADD_FIELD}
              </Button>
              <Button variant="dark" className="ml-3" onClick={onClickAddAll}>
                {S.ADD_ALL_FIELDS}
              </Button>
            </Col>
          </Row>
        ),
      getIncludeFieldsFooterActions:
        props =>
        ({ containerId, classes }) => {
          const { onClickRemove, onClickRemoveAll, selectedIncludeFields } =
            props;
          const noRowsSelected =
            !selectedIncludeFields?.length ||
            selectedIncludeFields?.length === 0;

          return (
            <Col className="d-flex flex-wrap">
              <Col lg={7} md={12} xs={12} className={classes.removeButtons}>
                <Button
                  variant="dark"
                  onClick={onClickRemove}
                  disabled={noRowsSelected}
                >
                  {S.REMOVE_FIELD}
                </Button>
                <Button
                  variant="dark"
                  className="ml-3"
                  onClick={onClickRemoveAll}
                >
                  {S.REMOVE_ALL_FIELDS}
                </Button>
              </Col>
              <Col lg={5} md={12} xs={12} className={classes.moveButtons}>
                <Button
                  variant="dark"
                  onClick={() => handlers.onClickMoveUp(props)(containerId)}
                  disabled={noRowsSelected}
                >
                  {S.MOVE_UP}
                </Button>
                <Button
                  variant="dark"
                  className="ml-3"
                  onClick={() => handlers.onClickMoveDown(props)(containerId)}
                  disabled={noRowsSelected}
                >
                  {S.MOVE_DOWN}
                </Button>
              </Col>
            </Col>
          );
        },
    };

    return handlers;
  })
);
