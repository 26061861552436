export const basename = (path = "") => path.split("/").reverse()[0];
/**
 * Extract name till extension
 * @param fileName
 * @returns {*}
 */
export const name = fileName => fileName.split(".").slice(0, -1).join(".");

export const getFileExtension = fileName => {
  const match = fileName.match(/\.[A-Za-z0-9]+$/);

  return match ? match[0] : "";
};

export const isXlsFile = fileExtension =>
  [".xls", ".xlsx"].includes(fileExtension);

export const getFileExtensionInfo = fileName => {
  const fileExtension = getFileExtension(fileName);

  return {
    fileExtension,
    isXlsFile: isXlsFile(fileExtension),
  };
};
