import { useCallback } from "react";
import PropTypes from "prop-types";
import { FormControl } from "@dpdgroupuk/mydpd-ui";
import { getValue } from "~/utils/object";
import { UPPERCASE } from "~/constants/strings";

export const TranslationDropdown = ({ row, values, onChange, classes }) => {
  const handleChange = useCallback(
    (e, value) => onChange(value, row.index),
    [onChange]
  );

  return (
    <FormControl.Dropdown
      value={getValue(row, "original.templateFieldTranslationCode", "")}
      values={values}
      onChange={handleChange}
      classes={classes}
      textTransform={UPPERCASE}
    />
  );
};

TranslationDropdown.propTypes = {
  row: PropTypes.object,
  values: PropTypes.array,
  onChange: PropTypes.func,
  classes: PropTypes.object,
};

export default TranslationDropdown;
