import { findIndex, isEqual, pick, some } from "lodash";
import { differenceWith } from "lodash/array";
import cloneDeep from "lodash/cloneDeep";
import { every, sortBy } from "lodash/collection";
import { isEmpty } from "lodash/lang";
import set from "lodash/set";
import * as XLSX from "xlsx/dist/xlsx.full.min.js";

import { TEMPLATE_TYPE } from "@dpdgroupuk/mydpd-enums";

import {
  ADDRESSBOOK_FILE_OPTIONS,
  ImportsFields,
  SHIPMENT_EXPORT_FILE_OPTIONS,
  SHIPMENT_FILE_OPTIONS,
  SHIPMENT_RECEIPT_FILE_OPTIONS,
} from "~/constants/forms";
import * as S from "~/constants/strings";
import { REGEX } from "~/models/validators";
import { getValue, mapArrayToObjectByKey } from "~/utils/object";
import { formatMessage } from "~/utils/string";
import TranslationDropdown from "~/pages/Imports/components/AiImportModal/components/TranslationDropdown";
import { hasDuplicates } from "~/utils/array";
import { getFileExtensionInfo } from "~/utils/fs";

const memoizedEmptyArray = [];

export const createId = () =>
  (performance.now().toString(36) + Math.random().toString(36)).replace(
    /\./g,
    ""
  );

export const getDefaultTemplateId = list => {
  if (isEmpty(list)) {
    return undefined;
  }
  const defaultTemplate = list.find(item => item.defaultTemplate);

  return !defaultTemplate ? list[0].templateId : defaultTemplate.templateId;
};

export const getExcludeFields = values =>
  getValue(values, ImportsFields.EXCLUDE, memoizedEmptyArray);

export const getIncludeFields = values =>
  getValue(values, ImportsFields.INCLUDE, memoizedEmptyArray)
    .map(field => {
      field?.templateFieldId === "null" &&
        set(field, "templateFieldId", field.templateFieldId.concat(createId()));
      return field;
    })
    .filter(Boolean);

export const getIgnoreField = fields =>
  fields.find(field => field.templateFieldId.startsWith("null"));

export const getMandatoryFields = fields =>
  fields.filter(field => field.isMandatory);

// @see https://it.dpduk.live/version/customer-shipping/sprint-2.1/diag_uaJRk76GAqAAhccr.html?id=1643984707500
export const setupAllFields = (excludeFields, includeFields) => {
  const newExcludeFields = cloneDeep(excludeFields).map(field => {
    field.templateFieldId === "null" &&
      set(field, "templateFieldId", field.templateFieldId.concat(createId()));
    return field;
  });

  return {
    [ImportsFields.EXCLUDE]: getIgnoreField(excludeFields)
      ? [getIgnoreField(excludeFields)]
      : [],
    [ImportsFields.INCLUDE]: cloneDeep(includeFields).concat(
      sortBy(newExcludeFields, [
        ({ isMandatory }) => !isMandatory,
        "templateFieldDescription",
      ])
    ),
  };
};

export const getReferenceSequence = fields => {
  const result = [];
  for (let i = 0; i < S.REFERENCE_FIELDS.length; i++) {
    !isEmpty(
      fields.find(field => field.templateFieldId === S.REFERENCE_FIELDS[i])
    ) &&
      result.push({
        label: formatMessage(S.REFERENCE_$, i + 1),
        value: i + 1,
      });
  }
  return result;
};

export const createPrintSequence = fields => [
  S.IMPORT_DEFAULT_SEQUENCE,
  ...getReferenceSequence(fields),
];

export const getNewExcludeFields = (selectedFields, excludeFields) =>
  selectedFields.reduce((acc, field) => {
    if (getIgnoreField(excludeFields)) {
      !field.isMandatory &&
        !field.templateFieldId.startsWith("null") &&
        acc.push(field);
    } else {
      !field.isMandatory &&
        !acc.find(field => field.templateFieldId === "null") &&
        acc.push({
          ...field,
          ...(field.templateFieldId.startsWith("null") && {
            templateFieldId: "null",
          }),
        });
    }
    return acc;
  }, []);

// @see https://it.dpduk.live/version/customer-shipping/sprint-2.1/diag_zHtWk76GAqAAhUhi.html?id=1644313730537
export const setupRemovedAllFields = (excludeFields, includeFields) => ({
  [ImportsFields.EXCLUDE]: cloneDeep(excludeFields).concat(
    getNewExcludeFields(includeFields, excludeFields)
  ),
  [ImportsFields.INCLUDE]: getMandatoryFields(includeFields),
});

export const getExcludeFieldsForMove = (excludeFields, selectedFields) => {
  const newExcludeFields = differenceWith(
    excludeFields,
    selectedFields,
    isEqual
  );
  getIgnoreField(selectedFields) &&
    newExcludeFields.push(getIgnoreField(selectedFields));
  const newSelectedFields = cloneDeep(selectedFields).map(field => {
    field.templateFieldId === "null" &&
      set(field, "templateFieldId", field.templateFieldId.concat(createId()));
    return field;
  });
  return { newExcludeFields, newSelectedFields };
};

export const setupDraggedExcludeFields = (
  excludeFields,
  includeFields,
  selectedFields,
  destination
) => {
  const { newExcludeFields, newSelectedFields } = getExcludeFieldsForMove(
    excludeFields,
    selectedFields
  );

  const newIncludeFields = [
    ...includeFields.slice(0, destination.index),
    ...newSelectedFields,
    ...includeFields.slice(destination.index),
  ];

  return {
    [ImportsFields.EXCLUDE]: newExcludeFields,
    [ImportsFields.INCLUDE]: newIncludeFields,
  };
};
// @see https://it.dpduk.live/version/customer-shipping/sprint-2.1/diag_kbsSnb6GAqAAhQ40.html?id=1644839206479
export const setupSelectedFields = (
  excludeFields,
  includeFields,
  selectedFields
) => {
  const { newExcludeFields, newSelectedFields } = getExcludeFieldsForMove(
    excludeFields,
    selectedFields
  );

  return {
    [ImportsFields.EXCLUDE]: newExcludeFields,
    [ImportsFields.INCLUDE]: cloneDeep(includeFields).concat(
      sortBy(newSelectedFields, [
        ({ isMandatory }) => !isMandatory,
        "templateFieldDescription",
      ])
    ),
  };
};

// @see https://it.dpduk.live/version/customer-shipping/sprint-2.1/diag_NOcWnb6GAqAAheYd.html?id=1644853097053
export const setupRemovedSelectedFields = (
  excludeFields,
  includeFields,
  selectedFields
) => ({
  [ImportsFields.EXCLUDE]: cloneDeep(excludeFields).concat(
    getNewExcludeFields(selectedFields, excludeFields)
  ),
  [ImportsFields.INCLUDE]: differenceWith(
    includeFields,
    selectedFields.filter(field => !field.isMandatory),
    isEqual
  ),
});

export const AVAILABLE_FIELDS_COLUMNS = [
  {
    Header: S.AVAILABLE_FIELDS,
    accessor: "templateFieldDescription",
    sortDescFirst: false,
    defaultCanSort: true,
    sortOnLoad: true,
  },
];

export const AI_SUGGESTED_COLUMNS = (
  templateTranslations,
  onTranslationChange,
  classes
) => [
  {
    Header: S.AI_SUGGESTED_FIELDS.toUpperCase(),
    accessor: "templateFieldDescription",
    style: { width: "100%" },
  },
  {
    Header: S.TRANSLATION,
    Cell: ({ row }) =>
      !row.original.templateFieldId.startsWith("null") && (
        <TranslationDropdown
          row={row}
          values={templateTranslations}
          onChange={onTranslationChange}
          classes={classes}
        />
      ),
  },
  {
    Header: S.MANDATORY,
    Cell: ({ row: { original } }) =>
      S.BOOLEAN_TO_MANDATORY[getValue(original, "isMandatory")],
    style: { minWidth: 124 },
  },
];

export const FILE_ORDER_COLUMNS = [
  {
    Header: S.FIELD_ORDER,
    accessor: "templateFieldDescription",
    style: { width: "100%" },
  },
  {
    Header: S.MANDATORY,
    Cell: ({ row: { original } }) =>
      S.BOOLEAN_TO_MANDATORY[getValue(original, "isMandatory")],
    style: { minWidth: 124 },
  },
];

export const IMPORTED_FIELD_COLUMNS = [
  {
    Header: S.IMPORTED_FILE_FIELDS.toUpperCase(),
    accessor: "importedFileFields",
  },
];

export const getFileOptionsByTemplates = templateCode => {
  switch (parseInt(templateCode)) {
    case TEMPLATE_TYPE.DPD_DIRECT_PRODUCT:
    case TEMPLATE_TYPE.PRODUCT:
    case TEMPLATE_TYPE.PRODUCT_BOOK:
      return [ImportsFields.PRODUCT_DELIMITER];

    case TEMPLATE_TYPE.SHIPMENT:
      return SHIPMENT_FILE_OPTIONS;

    case TEMPLATE_TYPE.SHIPMENT_RECEIPT:
      return SHIPMENT_RECEIPT_FILE_OPTIONS;

    case TEMPLATE_TYPE.SHIPMENT_EXPORT:
      return SHIPMENT_EXPORT_FILE_OPTIONS;

    case TEMPLATE_TYPE.DELIVERY_ADDRESS_BOOK:
    case TEMPLATE_TYPE.RETURN_ADDRESS_BOOK:
      return ADDRESSBOOK_FILE_OPTIONS;

    default:
      return [];
  }
};

export const reorderTable = (
  tableData,
  selectedFields,
  selectedRowIds,
  destinationIndex,
  sourceIndex
) =>
  tableData.reduce((acc, item, index) => {
    if (selectedRowIds[index]) {
      return destinationIndex === index ? [...acc, ...selectedFields] : acc;
    }

    if (destinationIndex !== index) {
      return [...acc, item];
    }

    return sourceIndex > destinationIndex
      ? [...acc, ...selectedFields, item]
      : [...acc, item, ...selectedFields];
  }, []);

export const getQuery = (
  formValues,
  shipmentImportTemplates,
  newTemplateName
) => {
  let id;
  const body = {
    ...pick(formValues, getFileOptionsByTemplates(formValues.definition)),
    [ImportsFields.INCLUDE]: {
      templateField: formValues[ImportsFields.INCLUDE].map(
        ({ templateFieldId: id, templateFieldTranslationCode }) => {
          const templateFieldId = id.startsWith("null") ? "null" : id;
          return formValues[ImportsFields.DEFINITION] !==
            TEMPLATE_TYPE.SHIPMENT.toString()
            ? {
                templateFieldId,
              }
            : { templateFieldId, templateFieldTranslationCode };
        }
      ),
    },
  };

  if (
    formValues[ImportsFields.DEFINITION] === TEMPLATE_TYPE.SHIPMENT.toString()
  ) {
    !formValues[ImportsFields.AUTO_PRINT] &&
      set(body, ImportsFields.PRINT_SEQUENCE, null);

    const selectedTemplate = shipmentImportTemplates.find(
      template => template.label === newTemplateName
    );
    if (selectedTemplate) {
      shipmentImportTemplates.length === 1 &&
        set(body, ImportsFields.DEFAULT_TEMPLATE, true);
      set(body, ImportsFields.TEMPLATE_NAME, selectedTemplate.label);
      id = selectedTemplate.value;
    } else {
      shipmentImportTemplates.length === 1 &&
        set(body, ImportsFields.DEFAULT_TEMPLATE, false);
      set(body, ImportsFields.TEMPLATE_NAME, newTemplateName);
    }
  }

  formValues[ImportsFields.DEFINITION] ===
    TEMPLATE_TYPE.DELIVERY_ADDRESS_BOOK.toString() &&
    set(body, "type", S.ADDRESSBOOK_TYPE_CODES.deliveryAddressbook);

  formValues[ImportsFields.DEFINITION] ===
    TEMPLATE_TYPE.RETURN_ADDRESS_BOOK.toString() &&
    set(body, "type", S.ADDRESSBOOK_TYPE_CODES.returnAddressbook);

  return { body, id };
};

export const getErrorMessage = values => {
  if (
    values[ImportsFields.DEFINITION] ===
      TEMPLATE_TYPE.SHIPMENT_RECEIPT.toString() &&
    !some(values[ImportsFields.INCLUDE], [
      "templateFieldId",
      "delivery_consignment_number",
    ]) &&
    !some(values[ImportsFields.INCLUDE], [
      "templateFieldId",
      "delivery_parcel_numbers",
    ])
  ) {
    return S.RECEIPT_TEMPLATE_MUST_CONTAIN_DELIVERY_FIELDS;
  }
  if (
    values[ImportsFields.DEFINITION] ===
      TEMPLATE_TYPE.SHIPMENT_EXPORT.toString() &&
    !values[ImportsFields.EXPORT_FILE].match(REGEX.FILE_EXTENSION_3_CHARACTERS)
  ) {
    return S.INCOMPLETE_EXPORT_FILE_NAME;
  }
  return "";
};

export const setupSelectedRowIds = (selectedFields = [], allFields) =>
  selectedFields.reduce((acc, row) => {
    const itemIndex = findIndex(
      allFields,
      field => field.templateFieldId === row.templateFieldId
    );

    if (itemIndex !== -1) {
      acc[itemIndex] = true;
    }

    return acc;
  }, {});

export const detectDelimiter = (string, delimiters) => {
  const availableDelimiters = new Set(delimiters);
  const delimitersMap = new Map();

  for (const symbol of string) {
    if (availableDelimiters.has(symbol)) {
      delimitersMap.set(symbol, (delimitersMap.get(symbol) || 0) + 1);
    }
  }

  let delimiter = null;
  let delimiterCount = 0;

  for (const [char, count] of delimitersMap) {
    if (count > delimiterCount) {
      delimiter = char;
      delimiterCount = count;
    }
  }

  return delimiter;
};

const aiImportOptions = {
  types: [
    {
      description: ".csv, .txt, .xls, .xlsx",
      accept: {
        "text/csv": [".csv"],
        "text/plain": [".txt"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      },
    },
  ],
  excludeAcceptAllOption: true,
  multiple: false,
};

export const getXlsFileContent = async file => {
  const data = await file.arrayBuffer();
  const workbook = XLSX.read(data, { type: "array" });
  const sheetName = workbook.SheetNames[0];
  const sheet = workbook.Sheets[sheetName];

  return XLSX.utils.sheet_to_csv(sheet);
};

export const getFileContent = async () => {
  let fileContent;

  const [fileHandle] = await window.showOpenFilePicker(aiImportOptions);
  const file = await fileHandle.getFile();

  const { fileExtension, isXlsFile } = getFileExtensionInfo(file.name);

  if (isXlsFile) {
    fileContent = await getXlsFileContent(file);
  } else {
    fileContent = await file.text();
  }

  return { isXlsFile, fileExtension, fileContent, file };
};

export const getAllFields = (excludeFields, includeFields) => {
  // NOTE: have to filter all ignore fields from includeFields
  const fields = setupRemovedAllFields(excludeFields, includeFields);

  return [...fields.includeFields, ...fields.excludeFields];
};

export const detectFileDelimiter = ({ isXlsFile, fileContent, delimiters }) => {
  const availableDelimiters = delimiters.map(({ value }) => value);
  const fileDelimiter = detectDelimiter(
    fileContent.split(/\r\n|\n/)[0], // NOTE: use only first row (header)
    availableDelimiters
  );
  return isXlsFile
    ? "," // NOTE: coma is default delimiter for XLS files
    : fileDelimiter;
};

export const getHeader = (fileContent, delimiter) =>
  fileContent
    .split(/\r\n|\n/)[0]
    .split(delimiter)
    .map(field => field.trim());

export const getFileData = async (delimiters, fields) => {
  const minRequiredHeaderLength = getMandatoryFields(fields).length;

  const { isXlsFile, fileContent, fileExtension, file } =
    await getFileContent();

  if (!fileContent) {
    throw new Error(`${S.EMPTY_FILE} ${S.CHECK_FILE}`);
  }

  if (!fileContent.split(/\r\n|\n/)[0]) {
    throw new Error(`${S.EMPTY_FIRST_ROW} ${S.CHECK_FILE}`);
  }

  const delimiter = detectFileDelimiter({
    isXlsFile,
    fileContent,
    delimiters,
  });

  // if delimiter (from .csv or .txt file) doesn't exist in available delimiters (for example, it's "#")
  if (!delimiter) {
    throw new Error(`${S.UNSUPPORTED_DELIMITER} ${S.CHECK_FILE}`);
  }

  const fileHeader = getHeader(fileContent, delimiter);

  if (every(fileHeader, field => !field)) {
    throw new Error(`${S.EMPTY_FIRST_ROW} ${S.CHECK_FILE}`);
  }

  if (hasDuplicates(fileHeader)) {
    throw new Error(`${S.DUPLICATED_HEADER_FIELDS} ${S.CHECK_FILE}`);
  }

  if (fileHeader.length < minRequiredHeaderLength) {
    throw new Error(`${S.EMPTY_FILE} ${S.CHECK_FILE}`);
  }

  return { fileHeader, delimiter, fileExtension, file };
};

export const getAiInitialFields = ({ fileHeader, allFields, aiResponse }) => {
  const fieldsKeyObject = mapArrayToObjectByKey(allFields, "templateFieldId");
  const aiFields = fileHeader.reduce(
    (acc, fieldName) => {
      const defaultField = {
        ...fieldsKeyObject[null],
        templateFieldId: `null${createId()}`, // NOTE: create unique id for each ignore field
      };
      const field = getValue(
        fieldsKeyObject,
        `${aiResponse[fieldName]}`,
        defaultField
      );
      const includeField = {
        ...field,
        templateFieldId:
          field.templateFieldId === "null"
            ? defaultField.templateFieldId
            : field.templateFieldId,
        templateFieldTranslationCode: null,
        templateFieldTranslationDescription: null,
      };

      acc.importedFields.push({
        importedFileFields: !!fieldName ? fieldName : S.IGNORE_FIELD,
      });
      acc.includeFields.push(includeField);

      return acc;
    },
    {
      importedFields: [],
      includeFields: [],
    }
  );
  const excludeFields = differenceWith(
    allFields,
    aiFields.includeFields,
    isEqual
  );

  return { ...aiFields, excludeFields };
};

export const getFileFormData = async (file, formDataFileName) => {
  const { isXlsFile } = getFileExtensionInfo(file.name);
  const formData = new FormData();

  if (isXlsFile) {
    const csvData = await getXlsFileContent(file);

    formData.append(
      formDataFileName,
      new Blob([csvData], { type: "text/csv" }),
      file.name
    );
  } else {
    formData.append(formDataFileName, file);
  }

  return formData;
};

export const validateFile = async (file, fileExtension) => {
  if (fileExtension && !file.name.endsWith(fileExtension)) {
    return formatMessage(S.PLEASE_SELECT_$, S.ONLY_CSV);
  }

  // If file has been changed and you will try to upload - Chrome will throw an error "ERR_UPLOAD_FILE_CHANGED"
  try {
    await file
      .slice(0, 1) // only the first byte
      .arrayBuffer();
  } catch (error) {
    return S.UPLOAD_FILE_CHANGED;
  }
};
