import { useCallback } from "react";

import classNames from "classnames";
import { isEmpty } from "lodash/lang";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { Field, getFormValues, propTypes, reduxForm } from "redux-form";

import { withAppContext, withNotifier } from "@dpdgroupuk/mydpd-app";
import {
  Button,
  Card,
  FormControl,
  withOverlay,
  withSnackbar,
} from "@dpdgroupuk/mydpd-ui";

import { TEST_IMPORT_FILE_FORM, TestFileEntity } from "~/constants/forms";
import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import * as S from "~/constants/strings";
import useToggle from "~/hooks/useToggle";
import { TemplateActions, TemplateSelectors } from "~/redux";
import { getErrorMessage } from "~/utils/error";

import TestImportFileModal from "./TestImportFileModal";
import { Col, Row } from "react-bootstrap";
import styles from "./TestImportFileModal.module.scss";
import { ImportsModels } from "~/pages/Imports/models";

const TestImportFile = ({
  shipmentTemplate,
  testImportFile,
  testImportFileResult,
  formValues,
  fileExtension,
  withLabel,
}) => {
  const testImportFileToggle = useToggle();

  const handleClick = useCallback(() => {
    testImportFile(testImportFileToggle.switchOn);
  }, [testImportFile, testImportFileToggle]);

  return (
    <Card className="h-100">
      <Card.Title
        className={classNames(
          "mb-4 d-flex justify-content-between",
          styles.cardTitle
        )}
      >
        {S.TEST_IMPORT_FILE}
      </Card.Title>

      <Row>
        {withLabel && (
          <Col md={7} xs={12} className="mb-3 mb-md-0">
            {S.TEST_IMPORT_FILE_HELP_MESSAGE}
          </Col>
        )}
        <Col md={withLabel ? 4 : 12} xs={12}>
          <Field
            component={FormControl.Input}
            label={S.TEST_FILE_LOCATION}
            name={TestFileEntity.FILE_PATH}
            type="file"
            accept={fileExtension}
            helperText={withLabel ? "" : S.TEST_IMPORT_FILE_HELP_MESSAGE}
            onBlur={event => {
              event.preventDefault(); // used to avoid setting empty object to store when prompt window was opened
            }}
          />
        </Col>
        <Col
          md={withLabel ? 1 : 12}
          xs={12}
          className={classNames(
            "d-flex",
            withLabel ? styles.btn : "justify-content-end"
          )}
        >
          <Button
            variant="primary"
            onClick={handleClick}
            disabled={
              !formValues[TestFileEntity.FILE_PATH] || !shipmentTemplate
            }
          >
            {S.TEST}
          </Button>
        </Col>
      </Row>

      <TestImportFileModal
        open={testImportFileToggle.value}
        onCancel={testImportFileToggle.switchOff}
        title={S.TEST_IMPORT_ERRORS}
        okButton={{ visible: true, text: S.OK }}
        size="md"
        testImportFileResult={testImportFileResult}
      />
    </Card>
  );
};

TestImportFile.propTypes = {
  ...propTypes,
  fileExtension: PropTypes.string,
  testImportFile: PropTypes.func,
  testImportFileResult: PropTypes.array,
  withLabel: PropTypes.bool,
};
TestImportFile.defaultProps = { fileExtension: ".csv", withLabel: false };

export default compose(
  withNotifier,
  withSnackbar,
  withAppContext,
  withOverlay,
  connect(state => ({
    formValues: getFormValues(TEST_IMPORT_FILE_FORM)(state) || {},
    testImportFileResult: TemplateSelectors.getTestImportFileResult(state),
  })),
  reduxForm({
    form: TEST_IMPORT_FILE_FORM,
    enableReinitialize: true,
  }),
  withHandlers({
    testImportFile: ({
      shipmentTemplate,
      formValues,
      overlay,
      dispatch,
      snackbar,
    }) =>
      overlay.showWhile(
        async switchTestImportFileToggleOn => {
          const file = formValues.filePath[0];

          const errorMessage = await ImportsModels.validateFile(file);

          if (errorMessage) {
            return snackbar.showAlert({
              message: errorMessage,
            });
          }

          const formData = await ImportsModels.getFileFormData(
            file,
            "fileImport"
          );

          try {
            const testFileResults = await dispatch(
              TemplateActions.testImportFile(
                formData,
                shipmentTemplate.templateId
              )
            );

            !isEmpty(testFileResults) && switchTestImportFileToggleOn();

            snackbar.showSuccess({
              message: isEmpty(testFileResults)
                ? S.TEST_IMPORT_FILE_SUCCESS_NO_ERRORS_FOUND
                : S.TEST_IMPORT_FILE_SUCCESS,
            });
          } catch (e) {
            snackbar.showAlert({
              message: getErrorMessage(e, S.TEST_IMPORT_FILE),
              displayTime: SHOW_ALERT_DISPLAY_TIME,
            });
          }
        },
        { entityName: S.TEST_IMPORT_FILE }
      ),
  })
)(TestImportFile);
